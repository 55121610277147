
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CollapseComponent extends Vue {
  @Prop() title!: string;
  @Prop({ type: Boolean, default: false }) visible!: boolean;

  show = false;

  created() {
    this.show = this.visible;
    if (this.visible) {
      this.$emit("createdvisible");
    }
  }

  onShow() {
    this.show = true;
    this.$emit("show");
  }

  onHide() {
    this.show = false;
    this.$emit("hide");
  }
}
