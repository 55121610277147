import { apiService } from '../api/api.service';
import { notificationService } from '../notification/notification.service';
import { Richtung } from './richtung.class';
import { RichtungInterface } from './richtung.interface';

class RichtungService {
  private richtungen: Richtung[] = [];

  private schnittstelle = 'richtung';

  async fetchAll() {
    try {
      this.setRichtungen(
        ((await apiService.get(this.schnittstelle)) as RichtungInterface[]).map(
          (r) => new Richtung(r),
        ),
      );
    } catch (e) {
      notificationService.sendErrorNotification(
        'Fehler beim Abfragen der Richtungen',
      );
    }
  }

  async fetchOne(id: number) {
    try {
      return new Richtung(
        (await apiService.get(
          this.schnittstelle + '/' + id,
        )) as RichtungInterface,
      );
    } catch (e) {
      notificationService.sendErrorNotification(
        `Fehler beim Abfragen der Richtung (${id})`,
      );
    }
  }

  async setRouteAktiv(richtungId: number, routeId: number) {
    try {
      await apiService.get(
        `${this.schnittstelle}/${richtungId}/route/${routeId}/aktiv`,
      );
      const richtung = this.findById(richtungId);
      richtung?.routen.forEach(
        (route) => (route.isAktiv = route.id === routeId),
      );
    } catch (e) {
      console.error(e);
      notificationService.sendErrorNotification(
        `Fehler beim aktiv schalten der Route`,
      );
    }
  }

  findIndex(richtung: Richtung) {
    return this.richtungen.findIndex((r) => richtung === r);
  }

  findById(id: number) {
    return this.richtungen.find((r) => r.id === id);
  }

  setRichtungen(richtungen: Richtung[]) {
    this.richtungen = richtungen;
  }
}

export const richtungService = new RichtungService();
