
import { Component, Vue } from "vue-property-decorator";
import { KameraLayer } from "../../../services/karte/kamera-layer";
import { karteService } from "../../../services/karte/karte.service";
import { VrzLayer } from "../../../services/karte/vrz-layer";

@Component
export default class InfrastrukturView extends Vue {
  onKarteReady() {
    // console.log("onKarteReady");
    this.kameraLayer?.add();
    this.vrzLayer?.add();
  }

  get kameraLayer() {
    return karteService.getLayer<KameraLayer>(KameraLayer);
  }

  get vrzLayer() {
    return karteService.getLayer<VrzLayer>(VrzLayer);
  }
}
