import { Point } from '@turf/turf';
import { NbaInterface } from './nba.interface';

export class Nba {
  id: string;
  sichtbar: boolean;
  anlagenbezeichnung: string;
  richtung: string;
  anlagenkennung: string;
  as: string;
  typ: string;
  zustand: string;
  reihung: string;
  strasse: string;
  verkehrszentrale: string;
  url: string;
  punkt: Point;

  constructor(nbaInterface: NbaInterface) {
    this.id = nbaInterface.id;
    this.sichtbar = nbaInterface.sichtbar;
    this.anlagenbezeichnung = nbaInterface.anlagenbezeichnung;
    this.richtung = nbaInterface.richtung;
    this.anlagenkennung = nbaInterface.anlagenkennung;
    this.as = nbaInterface.as;
    this.typ = nbaInterface.typ;
    this.zustand = nbaInterface.zustand;
    this.reihung = nbaInterface.reihung;
    this.strasse = nbaInterface.strasse;
    this.verkehrszentrale = nbaInterface.verkehrszentrale;
    this.url = nbaInterface.url;
    this.punkt = nbaInterface.punkt;
  }

  getFeature() {
    return {
      type: 'Feature',
      geometry: this.punkt,
      properties: {
        id: this.id,
        sichtbar: this.sichtbar,
        verkehrszentrale: this.verkehrszentrale,
        url: this.url
      },
    };
  }
}
