import { TrafficEventInterface } from './traffic-event.interface';

export class TrafficEvent {
  public id: string;
  public quelle: string;
  public type: string;
  public start_time?: Date;
  public end_time?: Date;
  public general_public_comment: string;
  public lage: string;
  public verwaltungsgrenze_id?: number;

  constructor(trafficEvent: TrafficEventInterface) {
    this.id = trafficEvent.id;
    this.quelle = trafficEvent.quelle;
    this.type = trafficEvent.type;
    if (trafficEvent.start_time) {
      this.start_time = new Date(trafficEvent.start_time);
    }
    if (trafficEvent.end_time) {
      this.end_time = new Date(trafficEvent.end_time);
    }
    this.general_public_comment = trafficEvent.general_public_comment;
    this.lage = trafficEvent.lage;
    this.verwaltungsgrenze_id = trafficEvent.verwaltungsgrenze_id;
  }

  // getFeatures() {
  //   const features: any[] = [
  //     {
  //       type: 'Feature',
  //       geometry: {
  //         type: 'Point',
  //         coordinates: this.pointCoordinates,
  //       },
  //       properties: {
  //         id: this.id,
  //       },
  //     },
  //   ];
  //   if (this.lineCoordinates) {
  //     features.push({
  //       type: 'Feature',
  //       geometry: {
  //         type: 'MultiLineString',
  //         coordinates: this.lineCoordinates,
  //       },
  //       properties: {
  //         id: this.id,
  //       },
  //     });
  //   }
  //   return features;
  // }
}
