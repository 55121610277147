import { KeycloakConfig } from 'keycloak-js';

class ConfigService {
    private GIT_TAG!: string;
    private KEYCLOAK_URL!: string;
    private KEYCLOAK_REALM!: string;
    private KEYCLOAK_CLIENT!: string;
    private MAP_URL!: string;
    private API_URL!: string;
    private WFMS_LINK!: string;
    private USE_TESTDATEN_REISEZEIT!: string;
    private SYSTEMUMGEBUNG!: string;


    async init() {
        const response = await fetch('/config/config.json');
        const config = await response.json();
        if (!config) {
            throw new Error('config.json ist nicht vorhanden');
        }

        if (!config.GIT_TAG) {
            throw new Error('config.json fehlt GIT_TAG');
        }
        this.GIT_TAG = config.GIT_TAG;

        if (!config.KEYCLOAK_URL) {
            throw new Error('config.json fehlt KEYCLOAK_URL');
        }
        this.KEYCLOAK_URL = config.KEYCLOAK_URL;

        if (!config.KEYCLOAK_REALM) {
            throw new Error('config.json fehlt KEYCLOAK_REALM');
        }
        this.KEYCLOAK_REALM = config.KEYCLOAK_REALM;

        if (!config.KEYCLOAK_CLIENT) {
            throw new Error('config.json fehlt KEYCLOAK_CLIENT');
        }
        this.KEYCLOAK_CLIENT = config.KEYCLOAK_CLIENT;

        if (!config.MAP_URL) {
            throw new Error('config.json fehlt MAP_URL');
        }
        this.MAP_URL = config.MAP_URL;

        if (!config.API_URL) {
            throw new Error('config.json fehlt API_URL');
        }
        this.API_URL = config.API_URL;

        if (!config.WFMS_LINK) {
            throw new Error('config.json fehlt WFMS_LINK');
        }
        this.WFMS_LINK = config.WFMS_LINK;

        if (!config.USE_TESTDATEN_REISEZEIT) {
            throw new Error('config.json fehlt USE_TESTDATEN_REISEZEIT');
        }
        this.USE_TESTDATEN_REISEZEIT = config.USE_TESTDATEN_REISEZEIT;

        if (!config.SYSTEMUMGEBUNG) {
            throw new Error('config.json fehlt SYSTEMUMGEBUNG');
        }
        this.SYSTEMUMGEBUNG = config.SYSTEMUMGEBUNG;

    }

    getGitTag() {
        return this.GIT_TAG;
    }

    getKeycloakConfig(): KeycloakConfig {
        return {
            url: this.KEYCLOAK_URL + '/auth/',
            realm: this.KEYCLOAK_REALM,
            clientId: this.KEYCLOAK_CLIENT,
        };
    }

    getMapUrl() {
        return this.MAP_URL;
    }

    getApiUrl() {
        return this.API_URL;
    }

    getWfmsLink() {
        return this.WFMS_LINK;
    }

    getUseTestdatenReiszeit(){
        return this.USE_TESTDATEN_REISEZEIT;
    }

    getSystemumgebung(){
        return this.SYSTEMUMGEBUNG;
    }

    // isProduction() {
    //     return process.env.NODE_ENV === 'production';
    // }

    // getVersion() {
    //     return process.env.VUE_APP_APPLICATION_VERSION ? process.env.VUE_APP_APPLICATION_VERSION : 'dev';
    // }
}

export const configService = new ConfigService();
