
import { Component, Prop, Vue } from 'vue-property-decorator';
import AOSTableHeadCell from './AOSTableHeadCell.component.vue';
import { ITableField } from './table-field.interface';
import { ITableFilter } from './table-filter.interface';
import { ITableFieldSorted } from './table-field-sorted.interface';

@Component({
  components: {
    AOSTableHeadCell,
  },
})
export default class AOSTableHead extends Vue {
  @Prop({
    required: true,
    type: Array,
  }) fields!: ITableField[];
  @Prop({
    required: false,
    type: Object,
    default: () => { return; },
  }) filters!: { [key: string]: ITableFilter };
  @Prop({
    required: true,
    type: Object,
  }) sortedField !: ITableFieldSorted;

}
