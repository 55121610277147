import { Richtung } from '../richtung/richtung.class';
import { KorridorInterface } from './korridor.interface';

export class Korridor {
  id: number;
  beschreibung: string;
  sort: number;
  updatedAt: Date;
  createdAt: Date;
  richtungen: Richtung[];

  constructor(korridorInterface: KorridorInterface) {
    this.id = korridorInterface.id;
    this.beschreibung = korridorInterface.beschreibung;
    this.sort = korridorInterface.sort;
    this.updatedAt = new Date(korridorInterface.updatedAt);
    this.createdAt = new Date(korridorInterface.createdAt);
    this.richtungen = korridorInterface.richtungen.sort((a,b) => a.sort - b.sort).map((s) => new Richtung(s));
  }
}
