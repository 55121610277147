import { configService } from '@/config/config.service';
import { KeycloakInstance } from 'keycloak-js';

declare let Keycloak: any;

class KeycloakService {
  keycloak!: KeycloakInstance;
  // Angabe in Sekunden!!!
  updateInterval = 30;

  private errorShown = false;

  async init(): Promise<boolean> {
    await this.loadScript();
    this.keycloak = new Keycloak(configService.getKeycloakConfig());
    const authenticated = await this.keycloak.init({
      onLoad: 'login-required',
      checkLoginIframe: false,
    });
    if (!authenticated) {
      throw new Error('Not Authorized');
    }
    this.updateToken(true);
    // Check wenn der Tab gefocust wird, zur sofortigen Kontrolle des Tokens
    window.onfocus = () => {
      this.updateToken(false);
    };
    return authenticated;
  }

  getToken() {
    return this.keycloak.token;
  }

  getUsername() {
    return (this.keycloak.idTokenParsed as any).preferred_username;
  }

  getRoles(): string[] {
    if (!this.keycloak.realmAccess) {
      return [];
    }
    return this.keycloak.realmAccess.roles;
  }

  canKarteLesen(): boolean {
    return this.getRoles().includes('knm_karte_lesen');
  }

  canKorridorLesen(): boolean {
    return this.getRoles().includes('knm_korridor_lesen');
  }

  canKorridorSchalten(): boolean {
    return this.getRoles().includes('knm_korridor_schalten');
  }

  logout() {
    this.keycloak.logout();
  }

  private async updateToken(timeout: boolean) {
    try {
      await this.keycloak.updateToken(this.updateInterval);
      if (timeout) {
        setTimeout(() => this.updateToken(true), this.updateInterval * 1000);
      }
    } catch (e) {
      this.showErrorMessageAndReload();
    }
  }

  private showErrorMessageAndReload() {
    if (this.errorShown) {
      return;
    }
    this.errorShown = true;
    const s1 = 'Ihre Authorisierung ist abgelaufen.';
    const s2 = 'Die Seite wird neugeladen und Sie werden auf die Login Seite weitergeleitet.';
    alert(s1 + ' ' + s2);
    window.location.reload();
  }

  private loadScript() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.setAttribute('src', configService.getKeycloakConfig().url + 'js/keycloak.js');
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        reject();
      };
      script.async = true;
      document.head.appendChild(script);
    });
  }
}

export const keycloakService = new KeycloakService();
