
import { Component, Prop, Vue } from 'vue-property-decorator';
import AOSTableHead from './AOSTableHead.component.vue';
import { ITableField } from './table-field.interface';
import { ITableFieldSorted } from './table-field-sorted.interface';
import { ITableFilter } from './table-filter.interface';
import { ITableItem } from './table-item.interface';
import { Validator } from '../../../helper/validator.helper';

@Component({
  components: {
    AOSTableHead,
  },
})
export default class AOSTable extends Vue {
  @Prop({
    required: true,
    type: Array,
  }) items!: ITableItem[];
  @Prop({
    required: true,
    type: Array,
  }) fields!: ITableField[];
  @Prop({
    required: false,
    type: Object,
    default: () => { return; },
  }) filters!: { [key: string]: ITableFilter };
  @Prop({
    required: false,
    type: Boolean,
  }) busy!: boolean;
  @Prop({ type: Boolean, default: false }) extended!: boolean;
  @Prop({ type: Boolean, default: false }) pointer!: boolean;

  sortedField: ITableFieldSorted = {
    key: null,
    sortedAsc: false,
    sortedDesc: false,
  };

  get fieldsDict() {
    return this.fields.reduce((dict, field) => {
      dict[field.key] = field;
      return dict;
    }, {} as { [key: string]: ITableField });
  }

  get sortedItems() {
    const sortedField = this.sortedField;
    const key = sortedField.key;
    if (!key) {
      return this.items;
    }
    const field = this.fieldsDict[key];
    return this.items.sort((a, b) => {
      let aa: any = sortedField.sortedDesc ? a[key] : b[key];
      let bb: any = sortedField.sortedDesc ? b[key] : a[key];
      aa = Validator.normalizeValue(field.formatter && !Validator.isDate(aa) ? field.formatter(aa) : aa);
      bb = Validator.normalizeValue(field.formatter && !Validator.isDate(bb) ? field.formatter(bb) : bb);

      if ((Validator.isDate(aa) && Validator.isDate(bb)) || (Validator.isNumber(aa) && Validator.isNumber(bb))) {
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      } else if (aa === '' && bb !== '') {
        return 1;
      } else if (aa !== '' && bb === '') {
        return -1;
      }

      return Validator.stringifyObjectValues(aa).localeCompare(Validator.stringifyObjectValues(bb));
    });
  }

  sortField(field: ITableField) {
    if (!field.sortable) {
      return;
    }
    if (this.sortedField.key !== field.key) {
      this.sortedField.sortedAsc = false;
      this.sortedField.sortedDesc = false;
    }
    this.sortedField.key = field.key;
    if (this.sortedField.sortedAsc) {
      this.sortedField.sortedAsc = false;
      this.sortedField.sortedDesc = true;
    } else {
      this.sortedField.sortedAsc = true;
      this.sortedField.sortedDesc = false;
    }
  }
}
