class KarteParameterService {
  // default-Werte für Zoom und Center
  public center: [number, number] = [10.273, 52.092];
  public zoom: number = 5.75;
  public layer: string[] = [];

  getCenter() {
    return this.center;
  }

  getZoom() {
    return this.zoom;
  }

  getActiveLayers() {
    return this.layer;
  }

  setCenter(x: number, y: number) {
    this.center[0] = x;
    this.center[1] = y;
  }

  setZoom(z: number) {
    this.zoom = z;
  }

  addLayer(l: string) {
    if(!this.layer.includes(l)){
        this.layer.push(l);
    }
  }

  removeLayer(l: string) {
    const index = this.layer.indexOf(l, 0);
    if (index > -1) {
      this.layer.splice(index, 1);
    }
  }
}

export const karteParameterService = new KarteParameterService();
