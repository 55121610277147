import { ICONS_LOS_OFF, ICONS_LOS_ON } from "@/assets";
import { ExpressionInputType, ExpressionSpecification, FilterSpecification } from "maplibre-gl";
import { karteService } from "./karte.service";
import { Layer } from "./layer";

export class VerkehrslageHighContrastVectorLayer extends Layer {
  legendeImageUrl = ICONS_LOS_OFF as any;
  legendeImageUrlOn = ICONS_LOS_ON as any;

  legende = [];

  sourceLayer = "verkehrslage_db";
  tmcSourceLayer = "lcl_line_db";

  verkehrslageKey = "verkehrslage_highcontrast_vector";
  verkehrslageSperrungDashedKey = this.verkehrslageKey + "-sperrung-dashed";
  tmcVerkehrslageKey = this.verkehrslageKey + "-tmc";
  tmcVerkehrslageSperrungDashedKey =
    this.tmcVerkehrslageKey + "-sperrung-dashed";

  // filter = {
  filter = {
    // isDirectionRight: ['==', ['get', 'direction'], 0],
    // isDirectionLeft: ['==', ['get', 'direction'], 1],
    isDirectionRight: ["==", ["get", "r_numlanes"], 0] as ExpressionSpecification | ExpressionInputType,
    isDirectionLeft: [">", ["get", "r_numlanes"], 0] as ExpressionSpecification | ExpressionInputType,
    isSperrung: ["==", ["get", "is_sperrung"], true] as ExpressionSpecification | ExpressionInputType,
    isStau: ["==", ["get", "los"], 0] as ExpressionSpecification | ExpressionInputType,
    isStockend: ["==", ["get", "los"], 1] as ExpressionSpecification | ExpressionInputType,
    isZaehfluessig: ["==", ["get", "los"], 2] as ExpressionSpecification | ExpressionInputType,
    isFrei: [">=", ["get", "los"], 3] as ExpressionSpecification | ExpressionInputType,

    tmcIsSperrung: ["==", ["get", "is_sperrung"], true] as ExpressionSpecification | ExpressionInputType,
    tmcIsStau: ["==", ["get", "los"], 0] as ExpressionSpecification | ExpressionInputType,
    tmcIsStockend: ["==", ["get", "los"], 1] as ExpressionSpecification | ExpressionInputType,
    tmcIsZaehfluessig: ["==", ["get", "los"], 2] as ExpressionSpecification | ExpressionInputType,
    tmcIsFrei: [">=", ["get", "los"], 3] as ExpressionSpecification | ExpressionInputType,
  };

  // Subsegment lineoffset
  lineOffset = [
    "interpolate",
    ["exponential", 1],
    ["zoom"],
    10,
    [
      "case",
      this.filter.isDirectionRight,
      1,
      this.filter.isDirectionLeft,
      -1,
      0,
    ],
    11,
    [
      "case",
      this.filter.isDirectionRight,
      1,
      this.filter.isDirectionLeft,
      -1,
      0,
    ],
  ];

  // TMC lineoffset
  tmcLineOffset = ["interpolate", ["exponential", 1], ["zoom"], 10, 1, 11, 3];
  
  constructor() {
    super("VL (hoher Kontrast)", "place_label_other");
  }

  async add() {
    this.layerAnchorName = "place_label_other";
    this.map.addLayer(
      {
        id: this.verkehrslageKey,
        type: "line",
        source: karteService.apiSourceKey,
        "source-layer": this.sourceLayer,
        layout: {
          "line-join": "miter",
        },
        paint: {
          "line-color": [
            "case",
            this.filter.isSperrung as ExpressionSpecification,
            "#000000",
            this.filter.isStau,
            "#d7301f",
            this.filter.isStockend,
            "#fc8d59",
            this.filter.isZaehfluessig,
            "#fdcc8a",
            this.filter.isFrei,
            "#fef0d9",
            "#fef0d9",
          ],
          "line-width": 2,
          "line-offset": this.lineOffset as any,
        },
      },
      this.layerAnchorName
    );

    this.map.addLayer(
      {
        id: this.verkehrslageSperrungDashedKey,
        type: "line",
        source: karteService.apiSourceKey,
        "source-layer": this.sourceLayer,
        filter: this.filter.isSperrung as FilterSpecification,
        layout: {
          "line-join": "miter",
        },
        paint: {
          "line-color": "#FFFFFF",
          "line-width": 2,
          "line-offset": this.lineOffset as any,
          "line-dasharray": [1, 1],
        },
      },
      this.layerAnchorName
    );

    this.map.addLayer(
      {
        id: this.tmcVerkehrslageKey,
        type: "line",
        source: karteService.apiSourceKey,
        "source-layer": this.tmcSourceLayer,
        layout: {
          "line-join": "miter",
        },
        paint: {
          "line-color": [
            "case",
            this.filter.tmcIsSperrung as any,
            "#000000",
            this.filter.tmcIsStau as any,
            "#d7301f",
            this.filter.tmcIsStockend,
            "#fc8d59",
            this.filter.tmcIsZaehfluessig,
            "#fdcc8a",
            this.filter.tmcIsFrei,
            "rgba(254, 240, 217, 1)",
            "#fef0d9",
          ],
          "line-width": 2,
          "line-offset": this.tmcLineOffset as any,
        },
      },
      this.layerAnchorName
    );

    this.map.addLayer(
      {
        id: this.tmcVerkehrslageSperrungDashedKey,
        type: "line",
        source: karteService.apiSourceKey,
        "source-layer": this.tmcSourceLayer,
        filter: this.filter.tmcIsSperrung as any,
        layout: {
          "line-join": "miter",
        },
        paint: {
          "line-color": "#FFFFFF",
          "line-width": 2,
          "line-offset": this.tmcLineOffset as any,
          "line-dasharray": [1, 1],
        },
      },
      this.layerAnchorName
    );

    this.visible = true;
  }

  async remove() {
    this.map.removeLayer(this.verkehrslageSperrungDashedKey);
    this.map.removeLayer(this.verkehrslageKey);
    this.map.removeLayer(this.tmcVerkehrslageSperrungDashedKey);
    this.map.removeLayer(this.tmcVerkehrslageKey);
    this.visible = false;
  }

  async update() {
    return;
  }

  async addEvents() {
    return;
  }

  async removeEvents() {
    return;
  }
}
