import { apiService } from '../api/api.service';
import { notificationService } from '../notification/notification.service';
import { Verwaltungsgrenze } from './verwaltungsgrenze.class';
import { VerwaltungsgrenzeInterface } from './verwaltungsgrenze.interface';

class VerwaltungsgrenzeService {
  private verwaltungsgrenzen: Verwaltungsgrenze[] = [];

  private schnittstelle = 'verwaltungsgrenze';

  getVerwaltungsgrenzen() {
    return this.verwaltungsgrenzen;
  }

  getOptions() {
    return this.verwaltungsgrenzen.map((v) => ({
      value: v.id,
      text: v.name,
    }));
  }

  findById(id: number) {
    return this.verwaltungsgrenzen.find((b) => b.id === id);
  }

  async fetchAll() {
    try {
      this.verwaltungsgrenzen = ((await apiService.get(
        this.schnittstelle,
      )) as VerwaltungsgrenzeInterface[]).map((b) => new Verwaltungsgrenze(b));
    } catch (e) {
      console.error(e)
      notificationService.sendErrorNotification(
        'Fehler beim Abfragen der Verwaltungsgrenzen',
      );
    }
  }

  async fetchOne(id: number) {
    try {
      return new Verwaltungsgrenze(
        (await apiService.get(
          this.schnittstelle + '/' + encodeURIComponent(id),
        )) as VerwaltungsgrenzeInterface,
      );
    } catch (e) {
      notificationService.sendErrorNotification(
        `Fehler beim Abfragen der Verwaltungsgrenze (${id})`,
      );
    }
  }
}

export const verwaltungsgrenzeService = new VerwaltungsgrenzeService();
