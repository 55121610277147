
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FormControl } from '@/helper/FormBuilder/form';

@Component
export default class TextInput extends Vue {
  @Prop() form!: FormControl;
  @Prop({ type: String, default: '' }) label!: string;
  @Prop({ type: String, default: null }) placeholder!: string | null;
  @Prop({ type: Boolean }) required!: boolean;
  @Prop({ type: Boolean }) disabled!: boolean;
  @Prop({ type: Boolean }) readonly!: boolean;
  @Prop({ type: Number }) maxlength!: number;

  getFormControl() {
    return this.form;
  }

  onInput($event: any) {
    this.getFormControl().patchValue($event);
    this.$emit('input');
  }
}
