import { ICONS_VZ_KARTE, ICONS_VZ_OFF, ICONS_VZ_ON } from '@/assets';
import { GeoJSONSource, Popup } from 'maplibre-gl';
import { karteService } from './karte.service';
import { Layer } from './layer';

export class VrzLayer extends Layer {
  legendeImageUrl = ICONS_VZ_OFF as any;
  legendeImageUrlOn = ICONS_VZ_ON as any;

  legende = [
    {
      imgUrl: ICONS_VZ_KARTE as any,
      desc: this.name,
    },
  ];

  sourcePolygonLayer = 'verwaltungsgrenze_polygon_db';
  sourceCenterLayer = 'verwaltungsgrenze_center_db';

  static KEY_NAME = 'VrzLayer';
  static KEYS = {
    ICONS: VrzLayer.KEY_NAME + '_icons',
    MARKER: VrzLayer.KEY_NAME + '_marker',
    POLYGON: VrzLayer.KEY_NAME + '_polygon',
    POLYGONFILL: VrzLayer.KEY_NAME + '_polygonfill',
  };
  eventFunction = {
    click: this.onFeatureClick.bind(this),
    mouseenter: this.onFeatureMouseEnter.bind(this),
    mouseleave: this.onFeatureMouseLeave.bind(this),
  };

  constructor() {
    super('VZ');
  }

  async add() {
    this.addEvents();
    try {
      await karteService.loadImage(ICONS_VZ_KARTE as any, VrzLayer.KEYS.MARKER);
    } catch (e) {
      console.error(e);
    }

    this.map.addLayer({
      id: VrzLayer.KEYS.ICONS,
      type: 'symbol',
      source: karteService.apiSourceKey,
      'source-layer': this.sourceCenterLayer,
      layout: {
        'text-anchor': 'center',
        'text-offset': [0, 1.2],
        'text-field': ['get', 'name'],
        'text-font': ['Open Sans Regular'],
        'text-size': 12,
        'icon-image': VrzLayer.KEYS.MARKER,
        'icon-size': 0.15,
      },
      paint: {
        'text-color': 'rgba(234, 234, 235, 1)',
        'text-halo-color': 'rgba(83, 86, 90, 1)',
        'text-halo-blur': 0,
        'text-halo-width': 1,
      },
    });

    this.map.addLayer(
      {
        id: VrzLayer.KEYS.POLYGON,
        type: 'line',
        source: karteService.apiSourceKey,
        'source-layer': this.sourcePolygonLayer,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#FF6A13',
          // 'line-color': ['get', 'color'],
          'line-width': 1,
          'line-dasharray': [1, 3],
        },
      },
      this.layerAnchorName,
    );

    this.map.addLayer(
      {
        id: VrzLayer.KEYS.POLYGONFILL,
        type: 'fill',
        source: karteService.apiSourceKey,
        'source-layer': this.sourcePolygonLayer,
        paint: {
          'fill-color': ['get', 'color'],
          // 'fill-color': '#ffeda0',
          'fill-opacity': 0.0,
        },
      },
      VrzLayer.KEYS.POLYGON,
    );

    this.update();
    this.visible = true;
  }

  async addEvents() {
    this.map.on('click', VrzLayer.KEYS.ICONS, this.eventFunction.click);
    this.map.on(
      'mouseenter',
      VrzLayer.KEYS.ICONS,
      this.eventFunction.mouseenter,
    );
    this.map.on(
      'mouseleave',
      VrzLayer.KEYS.ICONS,
      this.eventFunction.mouseleave,
    );
  }

  async removeEvents() {
    this.map.off('click', VrzLayer.KEYS.ICONS, this.eventFunction.click);
    this.map.off(
      'mouseenter',
      VrzLayer.KEYS.ICONS,
      this.eventFunction.mouseenter,
    );
    this.map.off(
      'mouseleave',
      VrzLayer.KEYS.ICONS,
      this.eventFunction.mouseleave,
    );
  }

  onFeatureClick(e: any) {
    const vrz = e.features[0].properties;
    new Popup()
      .setLngLat(e.lngLat)
      .setHTML(
        `
          <b>${vrz.name}</b>
          <table>
            <tbody>
              <tr>
                <th>Adresse:</th>
                <td class="text-nowrap">${vrz.adresse?vrz.adresse:'keine Information'}</td>
              </tr>
              <tr>
                <th>Ort:</th>
                <td>${vrz.ort?vrz.ort:'keine Information'}</td>
              </tr>
            </tbody>
          </table>
        `,
      )
      .addTo(this.map);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFeatureMouseEnter(e: any) {
    this.map.getCanvas().style.cursor = 'pointer';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFeatureMouseLeave(e: any) {
    this.map.getCanvas().style.cursor = '';
  }

  async remove() {
    this.removeEvents();
    if (this.map.getLayer(VrzLayer.KEYS.ICONS)) {
      this.map.removeLayer(VrzLayer.KEYS.ICONS);
    }
    if (this.map.getLayer(VrzLayer.KEYS.POLYGON)) {
      this.map.removeLayer(VrzLayer.KEYS.POLYGON);
    }
    if (this.map.getLayer(VrzLayer.KEYS.POLYGONFILL)) {
      this.map.removeLayer(VrzLayer.KEYS.POLYGONFILL);
    }
    this.visible = false;
  }

  async update() {
    return;
  }
}
