import { apiService } from '../api/api.service';
import { notificationService } from '../notification/notification.service';
import { ImportStand } from './import-stand.class';
import { ImportStandInterface } from './import-stand.interface';

class ImportStandService {
  private importStaende: ImportStand[] = [];

  private schnittstelle = 'import-stand';

  getImportStaende() {
    return this.importStaende.filter(
      (i) =>
        i.id === 'tic3-verkehrsmeldungen' ||
        i.id === 'tomtom-stauobjekte' ||
        i.id === 'tomtom-reisezeiten' ||
        i.id === 'baustelle-mia' ||
        i.id === 'tomtom-tmc',
    );
  }

  findById(id: string) {
    return this.importStaende.find((b) => b.id === id);
  }

  async fetchImportStaende() {
    try {
      this.importStaende = ((await apiService.get(
        this.schnittstelle,
      )) as ImportStandInterface[]).map((b) => new ImportStand(b));
    } catch (e) {
      notificationService.sendErrorNotification(
        'Fehler beim Abfragen der Import-Stände',
      );
    }
  }
}

export const importStandService = new ImportStandService();
