// eslint-disable-next-line
export namespace Formatter {
  // eslint-disable-next-line
  export function dateFormatter(date: Date | string) {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    return date.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  // eslint-disable-next-line
  export function dateAndTimeFormatter(date: Date) {
    return date.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  // eslint-disable-next-line
  export function getIsoDateString(date: Date) {
    return date.toISOString().split('T')[0];
  }
}
