import { render, staticRenderFns } from "./AOSSideBarLink.component.vue?vue&type=template&id=f73fb9c2&scoped=true&"
import script from "./AOSSideBarLink.component.vue?vue&type=script&lang=ts&"
export * from "./AOSSideBarLink.component.vue?vue&type=script&lang=ts&"
import style0 from "./AOSSideBarLink.component.vue?vue&type=style&index=0&id=f73fb9c2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f73fb9c2",
  null
  
)

export default component.exports