import { BaustelleProfund } from '../baustelle/baustelle-profund.class';
import { Dwista } from '../dwista/dwista.class';
import { Kamera } from '../kamera/kamera.class';
import { karteService } from '../karte/karte.service';
import { RouteLayer } from '../karte/route-layer';
import { RouteInterface } from './route.interface';
import { routeService } from './route.service';

export class Route {
  id: number;
  sort: number;
  beschreibung: string;
  isAktiv: boolean;
  isHauptroute: boolean;
  strassen: string[];
  aktuelleReisezeit: number;
  grundReisezeit: number;
  verlustzeit: number;
  baustellenIds?: string[];
  dwistaIds?: string[];
  kameraIds?: string[];
  createdAt: Date;
  updatedAt: Date;

  baustellen: BaustelleProfund[] = [];
  isLoadingBaustellen = false;

  dwistas: Dwista[] = [];
  isLoadingDwistas = false;

  kameras: Kamera[] = [];
  isLoadingKameras = false;

  reisezeitVerlust: number | null = null;

  constructor(routeInterface: RouteInterface) {
    this.id = routeInterface.id;
    this.sort = routeInterface.sort;
    this.beschreibung = routeInterface.beschreibung;
    this.isAktiv = routeInterface.isAktiv;
    this.isHauptroute = routeInterface.isHauptroute;
    this.strassen = routeInterface.strassen;
    this.aktuelleReisezeit = routeInterface.aktuelleReisezeit;
    this.grundReisezeit = routeInterface.grundReisezeit;
    this.verlustzeit = routeInterface.verlustzeit;
    this.baustellenIds = routeInterface.baustellenIds;
    this.dwistaIds = routeInterface.dwistaIds;
    this.kameraIds = routeInterface.kameraIds;
    this.createdAt = new Date(routeInterface.createdAt);
    this.updatedAt = new Date(routeInterface.updatedAt);
  }

  async fetchBaustellen() {
    this.isLoadingBaustellen = true;
    await routeService.fetchBaustellen(this);
    this.isLoadingBaustellen = false;
  }

  async fetchDwistas() {
    this.isLoadingDwistas = true;
    await routeService.fetchDwistas(this);
    this.isLoadingDwistas = false;
  }

  async fetchKameras() {
    this.isLoadingKameras = true;
    await routeService.fetchKameras(this);
    this.isLoadingKameras = false;
  }

  async toggleIsAktiv() {
    try {
      await routeService.toggleIsAktiv(this.id);
      this.isAktiv = !this.isAktiv;
      const routeLayer = karteService.getLayer<RouteLayer>(RouteLayer);
      if (routeLayer && routeLayer.visible) {
        routeLayer.reload();
      }
    } catch (e) {
      console.error(e);
    }
  }
}
