
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FormControl } from '@/helper/FormBuilder/form';
import { Formatter } from '@/helper/formatter';

@Component
export default class DateInput extends Vue {
  @Prop() form!: FormControl;
  @Prop({ type: String, default: '' }) label!: string;
  @Prop({ type: String, default: null }) placeholder!: string | null;
  @Prop({ type: Boolean }) required!: boolean;
  @Prop({ type: Boolean }) disabled!: boolean;
  @Prop({ type: String }) minDate!: string;
  @Prop({ type: String }) maxDate!: string;

  getFormControl() {
    return this.form;
  }

  dateValueFormatted(date: string) {
    return Formatter.dateFormatter(date);
  }

  onInput(newDateString: string) {
    this.getFormControl().patchValue(newDateString.trim());
    this.$emit('input', newDateString);
  }
}
