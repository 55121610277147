
  import { Component, Vue } from "vue-property-decorator";
  import Testdaten  from '../components/helper/Testdaten.component.vue'
  
  @Component({
    components: {
        Testdaten
    }
  })
  export default class TestdatenView extends Vue {}
  