
import { Component, Vue } from "vue-property-decorator";
import { keycloakService } from "../../keycloak/keycloak.service";

@Component
export default class AOSNavBar extends Vue {
  keycloakService = keycloakService;

  modeToggle() {
    // const systemPrefersDarkMode = window.matchMedia(
    //   "(prefers-color-scheme: dark)"
    // ).matches;

    // console.log("mode:", systemPrefersDarkMode);

    const body = document.querySelector("body");
    const modelabel = document.getElementById("mode-switch-label");
    if (body != null) {
      if (body.classList.contains("aos-light-theme")) {
        body.classList.add("aos-dark-theme");
        body.classList.remove("aos-light-theme");
        if (modelabel) {
          modelabel.innerHTML = "Dark";
        }
      } else {
        body.classList.remove("aos-dark-theme");
        body.classList.add("aos-light-theme");
        if (modelabel) {
          modelabel.innerHTML = "Light";
        }
      }
    }
  }
}
