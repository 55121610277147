
import { Component, Vue } from "vue-property-decorator";
import { karteService } from "@/services/karte/karte.service";
import { importStandService } from "@/services/import-stand/import-stand.service";
import { ICONS_SLIDE_OUT_RIGHT, ICONS_SLIDE_IN_RIGHT } from '@/assets';
import { Formatter } from '@/helper/formatter';

@Component
export default class KarteComponent extends Vue {
  karteService = karteService;
  importStandService = importStandService;
  Formatter = Formatter;

  isEditing = false;
  showLayerManager = true;
  showLayerManagerLegende = false;

  ICONS_SLIDE_OUT_RIGHT = ICONS_SLIDE_OUT_RIGHT;
  ICONS_SLIDE_IN_RIGHT = ICONS_SLIDE_IN_RIGHT;

  created() {
    this.updateImportStaende();
  }

  async updateImportStaende() {
    await importStandService.fetchImportStaende();
    setTimeout(() => this.updateImportStaende(), 1000 * 60);
  }

  async mounted() {
    await karteService.init();
    this.$emit("ready");
  }

  beforeDestroy() {
    karteService.destroy();
  }
}
