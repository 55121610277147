export enum NotificationVariants {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info',
}

interface Notification {
  nachricht: string;
  title: string;
  variant: NotificationVariants;
}

class NotificationService {
  defaultTimeout = 5000;
  notifications: Notification[] = [];

  createNotification(
    nachricht: string,
    title: string,
    variant = NotificationVariants.DEFAULT,
  ): Notification {
    return {
      nachricht,
      title,
      variant,
    };
  }

  sendNotification(notification: Notification) {
    this.notifications.push(notification);
    setTimeout(() => {
      const index = this.notifications.findIndex(
        (notificationTmp) => notificationTmp === notification,
      );
      this.notifications.splice(index, 1);
    }, this.defaultTimeout);
  }

  sendErrorNotification(nachricht: string, title = 'Fehler') {
    const notification = this.createNotification(
      nachricht,
      title,
      NotificationVariants.DANGER,
    );
    this.sendNotification(notification);
  }
}

export const notificationService = new NotificationService();
