
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FormControl } from '@/helper/FormBuilder/form';
import { Validators } from '@/helper/validators';

@Component
export default class NumberInput extends Vue {
  @Prop() form!: FormControl;
  @Prop({ type: String, default: '' }) label!: string;
  @Prop({ type: String, default: null }) placeholder!: string | null;
  @Prop({ type: Boolean }) required!: boolean;
  @Prop({ type: Boolean }) disabled!: boolean;
  @Prop({ type: Boolean }) readonly!: boolean;
  @Prop({ type: Number }) min!: number;
  @Prop({ type: Number }) max!: number;
  @Prop({ type: Number, default: 1 }) step!: number;

  getFormControl() {
    return this.form;
  }

  onlyNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 44) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  onInput($event: any) {
    this.getFormControl().patchValue($event);
    this.$emit('input');
  }
}
