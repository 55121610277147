import { apiService } from '../api/api.service';
import { notificationService } from '../notification/notification.service';
import { BaustelleMia } from './baustelle-mia.class';
import { BaustelleMiaInterface } from './baustelle-mia.interface';

class BaustelleMiaService {
  private baustellenMia: BaustelleMia[] = [];
  private tomtomStaus: BaustelleMia[] = [];

  private schnittstelle = 'extern/baustelle-mia';

  async fetchAll() {
    try {
      this.baustellenMia = ((await apiService.get(
        this.schnittstelle,
      )) as BaustelleMiaInterface[]).map((r) => new BaustelleMia(r));
    } catch (e) {
      notificationService.sendErrorNotification(
        'Fehler beim Abfragen der Mia Baustellen',
      );
    }
  }

  getAll() {
    return this.baustellenMia;
  }

  // async fetchAllStaus() {
  //   // console.log('tomtom-stauobjekt.service: fetchAllStaus()');
  //   // MW 2021-08-10: auskommentiert, da das das update verhindert
  //   // if (this.tomtomStaus.length !== 0) {
  //   //   return;
  //   // }
  //   try {
  //     // console.log(
  //     //   'fetch-url: ',
  //     //   this.schnittstelle + '/stau' + '?' + new Date().getTime(),
  //     // );
  //     // cache-buster
  //     this.tomtomStaus = ((await apiService.get(
  //       this.schnittstelle + '/stau' + '?' + new Date().getTime(),
  //     )) as TomtomStauobjektInterface[]).map((r) => new BaustelleMia(r));
  //   } catch (e) {
  //     notificationService.sendErrorNotification(
  //       'Fehler beim Abfragen der Tomtom Stauobjekte',
  //     );
  //   }
  // }

  // getStaus() {
  //   return this.tomtomStaus;
  // }

  // getStausFeatures() {
  //   return this.getStaus().reduce((features, stau) => {
  //     features.push(...stau.getFeatures());
  //     return features;
  //   }, [] as any[]);
  // }

  // findStauById(id: string) {
  //   return this.getStaus().find((stau) => stau.id === id);
  // }

  async fetchOne(id: string) {
    try {
      return new BaustelleMia(
        (await apiService.get(
          this.schnittstelle + '/' + encodeURIComponent(id),
        )) as BaustelleMiaInterface,
      );
    } catch (e) {
      notificationService.sendErrorNotification(
        `Fehler beim Abfragen der Mia Baustelle (${id})`,
      );
    }
  }
}

export const baustelleMiaService = new BaustelleMiaService();
