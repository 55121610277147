import { Tic3VerkehrsmeldungInterface } from './tic3-verkehrsmeldung.interface';

export class Tic3Verkehrsmeldung {
  public id: string;
  public beschreibung: string;
  public von?: Date;
  public bis?: Date;
  public lagebeschreibung: string;
  public lineCoordinates?: Array<Array<[number, number]>>;
  public pointCoordinates: [number, number];
  public eventType?:
    | 'einausfahrtsperrung'
    | 'aufhebung'
    | 'sperrung'
    | 'unfall'
    | 'baustelle'
    | 'stau'
    | 'glaette'
    | 'warnung'
    | 'vollsperrung';
  public eventName?: string;
  public eventbeschreibung?: string;
  public verkehrsbeschraenkung?: string;
  public createdAt: Date;
  public verwaltungsgrenzeId?: number;

  constructor(tic3Verkehrsmeldung: Tic3VerkehrsmeldungInterface) {
    this.id = tic3Verkehrsmeldung.id;
    this.beschreibung = tic3Verkehrsmeldung.beschreibung;
    if (tic3Verkehrsmeldung.von) {
      this.von = new Date(tic3Verkehrsmeldung.von);
    }
    if (tic3Verkehrsmeldung.bis) {
      this.bis = new Date(tic3Verkehrsmeldung.bis);
    }
    this.lagebeschreibung = tic3Verkehrsmeldung.lagebeschreibung;
    this.lineCoordinates = tic3Verkehrsmeldung.lineCoordinates;
    this.pointCoordinates = tic3Verkehrsmeldung.pointCoordinates;
    this.eventType = tic3Verkehrsmeldung.eventType;
    this.eventName = tic3Verkehrsmeldung.eventName;
    this.eventbeschreibung = tic3Verkehrsmeldung.eventbeschreibung;
    this.verkehrsbeschraenkung = tic3Verkehrsmeldung.verkehrsbeschraenkung;
    this.createdAt = new Date(tic3Verkehrsmeldung.createdAt);
    this.verwaltungsgrenzeId = tic3Verkehrsmeldung.verwaltungsgrenzeId;
  }

  getFeatures() {
    const features: any[] = [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: this.pointCoordinates,
        },
        properties: {
          id: this.id,
        },
      },
    ];
    if (this.lineCoordinates) {
      features.push({
        type: 'Feature',
        geometry: {
          type: 'MultiLineString',
          coordinates: this.lineCoordinates,
        },
        properties: {
          id: this.id,
        },
      });
    }
    return features;
  }
}
