import { Route } from '../route/route.class';
import { RichtungInterface } from './richtung.interface';

export class Richtung {
  id: number;
  sort: number;
  von: string;
  nach: string;
  routen: Route[];
  createdAt: Date;
  updatedAt: Date;

  constructor(richtungInterface: RichtungInterface) {
    this.id = richtungInterface.id;
    this.sort = richtungInterface.sort;
    this.von = richtungInterface.von;
    this.nach = richtungInterface.nach
    this.createdAt = new Date(richtungInterface.createdAt);
    this.updatedAt = new Date(richtungInterface.updatedAt);

    this.routen = richtungInterface.routen.sort((a,b) => a.sort - b.sort).map((r) => new Route(r));
  }
}
