import Vue from 'vue';
import App from './App.vue';
import { router } from './router';

// ##########################################
// CSS
import '@/css/style.css';
import '@/css/e21x.css';
import 'maplibre-gl/dist/maplibre-gl.css';

// ##########################################
// Bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
import '@/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// ##########################################
// Autobahn Styleguide Bulma
// Version 2
// import '@e21x/autobahn-styleguide/e21x-styleguide.css';
// Version 3
import "@aos/styleguide";

// ##########################################
// Split
import VueSplit from 'vue-split-panel';
Vue.use(VueSplit);

// ##########################################
// Fragments für Multiple Root Components
import { Plugin } from 'vue-fragment';
Vue.use(Plugin);

// ##########################################
// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faChevronDown,
  faChevronRight,
  faPlus,
  faMinus,
  faLongArrowAltRight
} from '@fortawesome/free-solid-svg-icons';

library.add(faChevronDown, faChevronRight, faPlus, faMinus, faLongArrowAltRight);

Vue.component('font-awesome-icon', FontAwesomeIcon);

// ##########################################
// CustomInput
import TextInput from '@/components/input/TextInput.vue';
Vue.component('text-input', TextInput);

import NumberInput from '@/components/input/NumberInput.vue';
Vue.component('number-input', NumberInput);

import TextAreaInput from '@/components/input/TextAreaInput.vue';
Vue.component('textarea-input', TextAreaInput);

import DateInput from '@/components/input/DateInput.vue';
Vue.component('date-input', DateInput);

import CheckboxInput from '@/components/input/CheckboxInput.vue';
Vue.component('checkbox-input', CheckboxInput);

import MultipleCheckboxInput from '@/components/input/MultipleCheckboxInput.vue';
Vue.component('multiple-checkbox-input', MultipleCheckboxInput);

import RadioInput from '@/components/input/RadioInput.vue';
Vue.component('radio-input', RadioInput);

import SelectInput from '@/components/input/SelectInput.vue';
Vue.component('select-input', SelectInput);

import FileInput from '@/components/input/FileInput.vue';
Vue.component('file-input', FileInput);

import TimeInput from '@/components/input/TimeInput.vue';
Vue.component('time-input', TimeInput);

// ##########################################
import KarteComponent from '@/components/Karte.component.vue';
Vue.component('karte', KarteComponent);

import CollapseButtonEditorComponent from '@/components/helper/CollapseButtonEditor.component.vue';
Vue.component('CollapseButtonEditorComponent', CollapseButtonEditorComponent);

import CollapseButtonComponent from '@/components/helper/CollapseButton.component.vue';
Vue.component('CollapseButtonComponent', CollapseButtonComponent);

import CollapseComponent from '@/components/helper/Collapse.component.vue';
Vue.component('CollapseComponent', CollapseComponent);

import LadenComponent from '@/components/helper/Laden.component.vue';
import { configService } from './config/config.service';
import { keycloakService } from './keycloak/keycloak.service';
Vue.component('LadenComponent', LadenComponent);

import ReisezeitComponent from '@/components/helper/Reisezeit.component.vue';
Vue.component('ReisezeitComponent', ReisezeitComponent);

import AktuelleReisezeitComponent from '@/components/helper/AktuelleReisezeit.component.vue';
Vue.component('AktuelleReisezeitComponent', AktuelleReisezeitComponent);

// ##########################################
Vue.config.productionTip = false;

(async () => {
  await configService.init();
  await keycloakService.init();
  new Vue({
    router,
    render: (h) => h(App),
  }).$mount('#app');
})();
