import { coerceToValidator, _findInFormBuilder } from './helper';
import {
  ValidatorFn,
  FormBuilderStatus,
  ValidationErrors,
  AbstractControlOptions,
} from './types';

export abstract class AbstractControl {
  onCollectionChange = () => {
    return;
  };
  privateParent!: FormGroup | FormArray;

  public readonly value: any;

  constructor(public validator: ValidatorFn | null) {}

  get parent(): FormGroup | FormArray {
    return this.privateParent;
  }

  public readonly status!: FormBuilderStatus;

  get valid(): boolean {
    return this.status === FormBuilderStatus.VALID;
  }
  get invalid(): boolean {
    return this.status === FormBuilderStatus.INVALID;
  }
  get pending(): boolean {
    return this.status === FormBuilderStatus.PENDING;
  }
  get disabled(): boolean {
    return this.status === FormBuilderStatus.DISABLED;
  }
  get enabled(): boolean {
    return this.status !== FormBuilderStatus.DISABLED;
  }

  public readonly errors!: ValidationErrors[] | null;
  public readonly pristine: boolean = true;

  get dirty(): boolean {
    return !this.pristine;
  }

  public readonly touched: boolean = false;

  get untouched(): boolean {
    return !this.touched;
  }

  isRequired(name: string) {
    const abstractControl: any = this.get(name);
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    if (abstractControl['controls']) {
      for (const controlName in abstractControl['controls']) {
        if (abstractControl['controls'][controlName]) {
          if (this.isRequired(abstractControl['controls'][controlName])) {
            return true;
          }
        }
      }
    }
    return false;
  }

  setValidators(newValidator: ValidatorFn | ValidatorFn[] | null): void {
    this.validator = coerceToValidator(newValidator);
  }

  clearValidators(): void {
    this.validator = null;
  }

  markAsTouched(opts: { onlySelf?: boolean } = {}): void {
    (this as { touched: boolean }).touched = true;

    if (this.privateParent && !opts.onlySelf) {
      this.privateParent.markAsTouched(opts);
    }
  }

  markAllAsTouched(): void {
    this.markAsTouched({ onlySelf: true });

    this._forEachChild((control: AbstractControl) =>
      control.markAllAsTouched(),
    );
  }

  markAsUntouched(opts: { onlySelf?: boolean } = {}): void {
    (this as { touched: boolean }).touched = false;

    this._forEachChild((control: AbstractControl) => {
      control.markAsUntouched({ onlySelf: true });
    });

    if (this.privateParent && !opts.onlySelf) {
      this.privateParent._updateTouched(opts);
    }
  }

  markAsDirty(opts: { onlySelf?: boolean } = {}): void {
    (this as { pristine: boolean }).pristine = false;

    if (this.privateParent && !opts.onlySelf) {
      this.privateParent.markAsDirty(opts);
    }
  }

  markAsPristine(opts: { onlySelf?: boolean } = {}): void {
    (this as { pristine: boolean }).pristine = true;

    this._forEachChild((control: AbstractControl) => {
      control.markAsPristine({ onlySelf: true });
    });

    if (this.privateParent && !opts.onlySelf) {
      this.privateParent._updatePristine(opts);
    }
  }

  markAsPending(opts: { onlySelf?: boolean; emitEvent?: boolean } = {}): void {
    (this as { status: string }).status = FormBuilderStatus.PENDING;

    if (this.privateParent && !opts.onlySelf) {
      this.privateParent.markAsPending(opts);
    }
  }

  disable(opts: { onlySelf?: boolean; emitEvent?: boolean } = {}): void {
    const skipPristineCheck = this._parentMarkedDirty(opts.onlySelf);

    (this as { status: string }).status = FormBuilderStatus.DISABLED;
    (this as { errors: ValidationErrors | null }).errors = null;
    this._forEachChild((control: AbstractControl) => {
      control.disable({ ...opts, onlySelf: true });
    });
    this._updateValue();

    this._updateAncestors({ ...opts, skipPristineCheck });
    this.onDisabledChange.forEach((changeFn) => changeFn(true));
  }

  enable(opts: { onlySelf?: boolean; emitEvent?: boolean } = {}): void {
    const skipPristineCheck = this._parentMarkedDirty(opts.onlySelf);

    (this as { status: string }).status = FormBuilderStatus.VALID;
    this._forEachChild((control: AbstractControl) => {
      control.enable({ ...opts, onlySelf: true });
    });
    this.updateValueAndValidity({ onlySelf: true, emitEvent: opts.emitEvent });

    this._updateAncestors({ ...opts, skipPristineCheck });
    this.onDisabledChange.forEach((changeFn: any) => changeFn(false));
  }

  _updateAncestors(opts: {
    onlySelf?: boolean;
    emitEvent?: boolean;
    skipPristineCheck?: boolean;
  }) {
    if (this.privateParent && !opts.onlySelf) {
      this.privateParent.updateValueAndValidity(opts);
      if (!opts.skipPristineCheck) {
        this.privateParent._updatePristine();
      }
      this.privateParent._updateTouched();
    }
  }

  setParent(parent: FormGroup | FormArray): void {
    this.privateParent = parent;
  }

  // eslint-disable-next-line
  abstract setValue(value: any, options?: Object): void;
  // eslint-disable-next-line
  abstract patchValue(value: any, options?: Object): void;
  // eslint-disable-next-line
  abstract reset(value?: any, options?: Object): void;

  updateValueAndValidity(
    opts: { onlySelf?: boolean; emitEvent?: boolean } = {},
  ): void {
    this._setInitialStatus();
    this._updateValue();

    if (this.enabled) {
      (this as {
        errors: ValidationErrors | null;
      }).errors = this._runValidator();
      (this as { status: string }).status = this._calculateStatus();
    }

    if (opts.emitEvent !== false) {
      this.markAsDirty();
      this.markAsTouched();
    }

    if (this.privateParent && !opts.onlySelf) {
      this.privateParent.updateValueAndValidity(opts);
    }
  }

  _updateTreeValidity(opts: { emitEvent?: boolean } = { emitEvent: true }) {
    this._forEachChild((ctrl: AbstractControl) =>
      ctrl._updateTreeValidity(opts),
    );
    this.updateValueAndValidity({ onlySelf: true, emitEvent: opts.emitEvent });
  }

  _setInitialStatus() {
    (this as { status: string }).status = this._allControlsDisabled()
      ? FormBuilderStatus.DISABLED
      : FormBuilderStatus.VALID;
  }

  _runValidator(): ValidationErrors | null {
    return this.validator ? this.validator(this) : null;
  }

  setErrors(
    errors: ValidationErrors | null,
    opts: { emitEvent?: boolean } = {},
  ): void {
    (this as { errors: ValidationErrors | null }).errors = errors;
    this._updateControlsErrors(opts.emitEvent !== false);
  }

  get(path: Array<string | number> | string): AbstractControl {
    return _findInFormBuilder(this, path, '.') as AbstractControl;
  }

  getError(errorCode: string, path?: Array<string | number> | string): any {
    const control = path ? this.get(path) : this;
    return control && control.errors
      ? (control.errors as any)[errorCode]
      : null;
  }

  hasError(errorCode: string, path?: Array<string | number> | string): boolean {
    return !!this.getError(errorCode, path);
  }

  get root(): AbstractControl {
    // eslint-disable-next-line
    let x: AbstractControl = this;

    while (x.privateParent) {
      x = x.privateParent;
    }

    return x;
  }

  _updateControlsErrors(emitEvent: boolean): void {
    (this as { status: string }).status = this._calculateStatus();

    if (this.privateParent) {
      this.privateParent._updateControlsErrors(emitEvent);
    }
  }

  _calculateStatus(): string {
    if (this._allControlsDisabled()) {
      return FormBuilderStatus.DISABLED;
    }
    if (this.errors) {
      return FormBuilderStatus.INVALID;
    }
    if (this._anyControlsHaveStatus(FormBuilderStatus.PENDING)) {
      return FormBuilderStatus.PENDING;
    }
    if (this._anyControlsHaveStatus(FormBuilderStatus.INVALID)) {
      return FormBuilderStatus.INVALID;
    }
    return FormBuilderStatus.VALID;
  }

  abstract _updateValue(): void;
  abstract _forEachChild(cb: Function): void;
  abstract _anyControls(condition: Function): boolean;
  abstract _allControlsDisabled(): boolean;
  abstract _syncPendingControls(): boolean;
  _anyControlsHaveStatus(status: string): boolean {
    return this._anyControls(
      (control: AbstractControl) => control.status === status,
    );
  }
  _anyControlsDirty(): boolean {
    return this._anyControls((control: AbstractControl) => control.dirty);
  }
  _anyControlsTouched(): boolean {
    return this._anyControls((control: AbstractControl) => control.touched);
  }
  _updatePristine(opts: { onlySelf?: boolean } = {}): void {
    (this as { pristine: boolean }).pristine = !this._anyControlsDirty();

    if (this.privateParent && !opts.onlySelf) {
      this.privateParent._updatePristine(opts);
    }
  }
  _updateTouched(opts: { onlySelf?: boolean } = {}): void {
    (this as { touched: boolean }).touched = this._anyControlsTouched();

    if (this.privateParent && !opts.onlySelf) {
      this.privateParent._updateTouched(opts);
    }
  }
  onDisabledChange: Function[] = [];
  _isBoxedValue(formState: any): boolean {
    return (
      typeof formState === 'object' &&
      formState !== null &&
      Object.keys(formState).length === 2 &&
      'value' in formState &&
      'disabled' in formState
    );
  }
  _registerOnCollectionChange(fn: () => void): void {
    this.onCollectionChange = fn;
  }
  _parentMarkedDirty(onlySelf?: boolean): boolean {
    const parentDirty = this.privateParent && this.privateParent.dirty;
    return !onlySelf && parentDirty && !this.privateParent._anyControlsDirty();
  }
}

export class FormControl extends AbstractControl {
  onChange: Function[] = [];
  pendingValue: any;
  pendingChange: any;
  constructor(
    formState: any = null,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
  ) {
    super(coerceToValidator(validatorOrOpts));
    this._applyFormState(formState);
    this.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  setValue(
    value: any,
    options: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    } = {},
  ): void {
    (this as { value: any }).value = this.pendingValue = value;
    if (this.onChange.length && options.emitModelToViewChange !== false) {
      this.onChange.forEach((changeFn) =>
        changeFn(this.value, options.emitViewToModelChange !== false),
      );
    }
    this.updateValueAndValidity(options);
  }

  patchValue(
    value: any,
    options: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    } = {},
  ): void {
    this.setValue(value, options);
  }

  reset(
    formState: any = null,
    options: { onlySelf?: boolean; emitEvent?: boolean } = {},
  ): void {
    this._applyFormState(formState);
    this.markAsPristine(options);
    this.markAsUntouched(options);
    this.setValue(this.value, options);
    this.pendingChange = false;
  }

  _updateValue() {
    return;
  }
  _anyControls(condition: Function): boolean {
    return false;
  }
  _allControlsDisabled(): boolean {
    return this.disabled;
  }
  registerOnChange(fn: Function): void {
    this.onChange.push(fn);
  }
  _clearChangeFns(): void {
    this.onChange = [];
    this.onDisabledChange = [];
    this.onCollectionChange = () => {
      return;
    };
  }
  registeronDisabledChange(fn: (isDisabled: boolean) => void): void {
    this.onDisabledChange.push(fn);
  }
  _forEachChild(cb: Function): void {
    return;
  }
  _syncPendingControls(): boolean {
    return false;
  }

  _applyFormState(formState: any) {
    if (this._isBoxedValue(formState)) {
      (this as { value: any }).value = this.pendingValue = formState.value;
      formState.disabled
        ? this.disable({ onlySelf: true, emitEvent: false })
        : this.enable({ onlySelf: true, emitEvent: false });
    } else {
      (this as { value: any }).value = this.pendingValue = formState;
    }
  }
}

export class FormGroup extends AbstractControl {
  constructor(
    public controls: { [key: string]: AbstractControl },
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
  ) {
    super(coerceToValidator(validatorOrOpts));
    this._setUpControls();
    this.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  registerControl(name: string, control: AbstractControl): AbstractControl {
    if (this.controls[name]) {
      return this.controls[name];
    }
    this.controls[name] = control;
    control.setParent(this);
    control._registerOnCollectionChange(this.onCollectionChange);
    return control;
  }

  addControl(name: string, control: AbstractControl): void {
    this.registerControl(name, control);
    this.updateValueAndValidity();
    this.onCollectionChange();
  }

  removeControl(name: string): void {
    if (this.controls[name]) {
      this.controls[name]._registerOnCollectionChange(() => {
        return;
      });
    }
    delete this.controls[name];
    this.updateValueAndValidity();
    this.onCollectionChange();
  }

  setControl(name: string, control: AbstractControl): void {
    if (this.controls[name]) {
      this.controls[name]._registerOnCollectionChange(() => {
        return;
      });
    }
    delete this.controls[name];
    if (control) {
      this.registerControl(name, control);
    }
    this.updateValueAndValidity();
    this.onCollectionChange();
  }

  contains(controlName: string): boolean {
    return (
      // eslint-disable-next-line
      this.controls.hasOwnProperty(controlName) &&
      this.controls[controlName].enabled
    );
  }

  setValue(
    value: { [key: string]: any },
    options: { onlySelf?: boolean; emitEvent?: boolean } = {},
  ): void {
    this._checkAllValuesPresent(value);
    Object.keys(value).forEach((name) => {
      this._throwIfControlMissing(name);
      this.controls[name].setValue(value[name], {
        onlySelf: true,
        emitEvent: options.emitEvent,
      });
    });
    this.updateValueAndValidity(options);
  }

  patchValue(
    value: { [key: string]: any },
    options: { onlySelf?: boolean; emitEvent?: boolean } = {},
  ): void {
    Object.keys(value).forEach((name) => {
      if (this.controls[name]) {
        this.controls[name].patchValue(value[name], {
          onlySelf: true,
          emitEvent: options.emitEvent,
        });
      }
    });
    this.updateValueAndValidity(options);
  }

  reset(
    value: any = {},
    options: { onlySelf?: boolean; emitEvent?: boolean } = {},
  ): void {
    this._forEachChild((control: AbstractControl, name: string) => {
      control.reset(value[name], {
        onlySelf: true,
        emitEvent: options.emitEvent,
      });
    });
    this._updatePristine(options);
    this._updateTouched(options);
    this.updateValueAndValidity(options);
  }

  getRawValue(): any {
    return this._reduceChildren(
      {},
      (
        acc: { [k: string]: AbstractControl },
        control: AbstractControl,
        name: string,
      ) => {
        acc[name] =
          control instanceof FormControl
            ? control.value
            : (control as any).getRawValue();
        return acc;
      },
    );
  }

  _syncPendingControls(): boolean {
    const subtreeUpdated = this._reduceChildren(
      false,
      (updated: boolean, child: AbstractControl) => {
        return child._syncPendingControls() ? true : updated;
      },
    );
    if (subtreeUpdated) {
      this.updateValueAndValidity({ onlySelf: true });
    }
    return subtreeUpdated;
  }

  _throwIfControlMissing(name: string): void {
    if (!Object.keys(this.controls).length) {
      throw new Error(`
          There are no form controls registered with this group yet.  If you're using ngModel,
          you may want to check next tick (e.g. use setTimeout).
        `);
    }
    if (!this.controls[name]) {
      throw new Error(`Cannot find form control with name: ${name}.`);
    }
  }

  _forEachChild(cb: (v: any, k: string) => void): void {
    Object.keys(this.controls).forEach((k) => cb(this.controls[k], k));
  }

  _setUpControls(): void {
    this._forEachChild((control: AbstractControl) => {
      control.setParent(this);
      control._registerOnCollectionChange(this.onCollectionChange);
    });
  }

  _updateValue(): void {
    (this as { value: any }).value = this._reduceValue();
  }

  _anyControls(condition: Function): boolean {
    let res = false;
    this._forEachChild((control: AbstractControl, name: string) => {
      res = res || (this.contains(name) && condition(control));
    });
    return res;
  }

  _reduceValue() {
    return this._reduceChildren(
      {},
      (
        acc: { [k: string]: AbstractControl },
        control: AbstractControl,
        name: string,
      ) => {
        if (control.enabled || this.disabled) {
          acc[name] = control.value;
        }
        return acc;
      },
    );
  }

  _reduceChildren(initValue: any, fn: Function) {
    let res = initValue;
    this._forEachChild((control: AbstractControl, name: string) => {
      res = fn(res, control, name);
    });
    return res;
  }

  _allControlsDisabled(): boolean {
    for (const controlName of Object.keys(this.controls)) {
      if (this.controls[controlName].enabled) {
        return false;
      }
    }
    return Object.keys(this.controls).length > 0 || this.disabled;
  }

  _checkAllValuesPresent(value: any): void {
    this._forEachChild((control: AbstractControl, name: string) => {
      if (value[name] === undefined) {
        throw new Error(
          `Must supply a value for form control with name: '${name}'.`,
        );
      }
    });
  }
}

export class FormArray extends AbstractControl {
  constructor(
    public controls: AbstractControl[],
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
  ) {
    super(coerceToValidator(validatorOrOpts));
    this._setUpControls();
    this.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  at(index: number): AbstractControl {
    return this.controls[index];
  }

  push(control: AbstractControl): void {
    this.controls.push(control);
    this._registerControl(control);
    this.updateValueAndValidity();
    this.onCollectionChange();
  }

  insert(index: number, control: AbstractControl): void {
    this.controls.splice(index, 0, control);
    this._registerControl(control);
    this.updateValueAndValidity();
  }

  removeAt(index: number): void {
    if (this.controls[index]) {
      this.controls[index]._registerOnCollectionChange(() => {
        return;
      });
    }
    this.controls.splice(index, 1);
    this.updateValueAndValidity();
  }

  setControl(index: number, control: AbstractControl): void {
    if (this.controls[index]) {
      this.controls[index]._registerOnCollectionChange(() => {
        return;
      });
    }
    this.controls.splice(index, 1);

    if (control) {
      this.controls.splice(index, 0, control);
      this._registerControl(control);
    }

    this.updateValueAndValidity();
    this.onCollectionChange();
  }

  get length(): number {
    return this.controls.length;
  }

  setValue(
    value: any[],
    options: { onlySelf?: boolean; emitEvent?: boolean } = {},
  ): void {
    this._checkAllValuesPresent(value);
    value.forEach((newValue: any, index: number) => {
      this._throwIfControlMissing(index);
      this.at(index).setValue(newValue, {
        onlySelf: true,
        emitEvent: options.emitEvent,
      });
    });
    this.updateValueAndValidity(options);
  }

  patchValue(
    value: any[],
    options: { onlySelf?: boolean; emitEvent?: boolean } = {},
  ): void {
    value.forEach((newValue: any, index: number) => {
      if (this.at(index)) {
        this.at(index).patchValue(newValue, {
          onlySelf: true,
          emitEvent: options.emitEvent,
        });
      }
    });
    this.updateValueAndValidity(options);
  }

  reset(
    value: any = [],
    options: { onlySelf?: boolean; emitEvent?: boolean } = {},
  ): void {
    this._forEachChild((control: AbstractControl, index: number) => {
      control.reset(value[index], {
        onlySelf: true,
        emitEvent: options.emitEvent,
      });
    });
    this._updatePristine(options);
    this._updateTouched(options);
    this.updateValueAndValidity(options);
  }

  getRawValue(): any[] {
    return this.controls.map((control: AbstractControl) => {
      return control instanceof FormControl
        ? control.value
        : (control as any).getRawValue();
    });
  }

  clear(): void {
    if (this.controls.length < 1) {
      return;
    }
    this._forEachChild((control: AbstractControl) =>
      control._registerOnCollectionChange(() => {
        return;
      }),
    );
    this.controls.splice(0);
    this.updateValueAndValidity();
  }

  _syncPendingControls(): boolean {
    const subtreeUpdated = this.controls.reduce(
      (updated: boolean, child: AbstractControl) => {
        return child._syncPendingControls() ? true : updated;
      },
      false,
    );
    if (subtreeUpdated) {
      this.updateValueAndValidity({ onlySelf: true });
    }
    return subtreeUpdated;
  }

  _throwIfControlMissing(index: number): void {
    if (!this.controls.length) {
      throw new Error(`
          There are no form controls registered with this array yet.  If you're using ngModel,
          you may want to check next tick (e.g. use setTimeout).
        `);
    }
    if (!this.at(index)) {
      throw new Error(`Cannot find form control at index ${index}`);
    }
  }

  _forEachChild(cb: Function): void {
    this.controls.forEach((control: AbstractControl, index: number) => {
      cb(control, index);
    });
  }

  _updateValue(): void {
    (this as { value: any }).value = this.controls
      .filter((control) => control.enabled || this.disabled)
      .map((control) => control.value);
  }

  _anyControls(condition: Function): boolean {
    return this.controls.some(
      (control: AbstractControl) => control.enabled && condition(control),
    );
  }

  _setUpControls(): void {
    this._forEachChild((control: AbstractControl) =>
      this._registerControl(control),
    );
  }

  _checkAllValuesPresent(value: any): void {
    this._forEachChild((control: AbstractControl, i: number) => {
      if (value[i] === undefined) {
        throw new Error(`Must supply a value for form control at index: ${i}.`);
      }
    });
  }

  _allControlsDisabled(): boolean {
    for (const control of this.controls) {
      if (control.enabled) {
        return false;
      }
    }
    return this.controls.length > 0 || this.disabled;
  }

  _registerControl(control: AbstractControl) {
    control.setParent(this);
    control._registerOnCollectionChange(this.onCollectionChange);
  }
}
