import { ValidatorFn, ValidationErrors } from './types';
import { AbstractControl } from './form';
import { Validators } from '../validators';
import { FormGroup } from './model';

function _mergeErrors(
  arrayOfErrors: ValidationErrors[],
): ValidationErrors | null {
  const result: { [key: string]: any } = arrayOfErrors.reduce(
    (res: ValidationErrors | null, errors: ValidationErrors | null) => {
      return errors != null ? { ...res!, ...errors } : res!;
    },
    {},
  );
  return Object.keys(result).length === 0 ? null : result;
}

function _executeValidators(
  control: AbstractControl,
  validators: ValidatorFn[],
): any[] {
  return validators.map((v) => v(control));
}

function isPresent(o: any): boolean {
  return o != null;
}

function isEmptyInputValue(value: any): boolean {
  return value == null || value.length === 0;
}

const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
// XX:XX
const TIME_REGEXP = new RegExp('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$');
// DD.MM.YYYY
// eslint-disable-next-line
const LOCAL_DATE_REGEXP = new RegExp(
  '^s*(3[01]|[12][0-9]|0?[1-9]).(1[012]|0?[1-9]).((?:19|20)d{2})s*$',
);
// YYYY-MM-DD
const DATE_REGEXP = new RegExp(
  /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/,
);

export class FormBuilderValidators {
  static compose(validators: null): null;
  static compose(
    validators: Array<ValidatorFn | null | undefined>,
  ): ValidatorFn | null;
  static compose(
    validators: Array<ValidatorFn | null | undefined> | null,
  ): ValidatorFn | null {
    if (!validators) {
      return null;
    }
    const presentValidators: ValidatorFn[] = validators.filter(
      isPresent,
    ) as any;
    if (presentValidators.length === 0) {
      return null;
    }

    return (control: AbstractControl) => {
      return _mergeErrors(_executeValidators(control, presentValidators));
    };
  }

  static nullValidator(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  static isNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null;
      }
      const isNumber = Validators.isNumber(control.value);
      return isNumber ? null : { isNumber: { actual: typeof control.value } };
    };
  }

  static min(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || isEmptyInputValue(min)) {
        return null;
      }
      const value = parseFloat(control.value);
      return !isNaN(value) && value < min
        ? { min: { min, actual: control.value } }
        : null;
    };
  }

  static max(max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || isEmptyInputValue(max)) {
        return null;
      }
      const value = parseFloat(control.value);
      return !isNaN(value) && value > max
        ? { max: { max, actual: control.value } }
        : null;
    };
  }

  static required(control: AbstractControl): ValidationErrors | null {
    return isEmptyInputValue(control.value) ? { required: true } : null;
  }

  static requiredTrue(control: AbstractControl): ValidationErrors | null {
    return control.value === true ? null : { required: true };
  }

  static email(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null;
    }
    return EMAIL_REGEXP.test(control.value) ? null : { email: true };
  }

  static minLength(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null;
      }
      const length: number =
        control.value || control.value === 0 ? String(control.value).length : 0;
      return length < minLength
        ? { minlength: { requiredLength: minLength, actualLength: length } }
        : null;
    };
  }

  static maxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const length: number = control.value ? control.value.length : 0;
      return length > maxLength
        ? { maxlength: { requiredLength: maxLength, actualLength: length } }
        : null;
    };
  }

  static pattern(pattern: string | RegExp): ValidatorFn {
    if (!pattern) {
      return FormBuilderValidators.nullValidator;
    }
    let regex: RegExp;
    let regexStr: string;
    if (typeof pattern === 'string') {
      regexStr = '';

      if (pattern.charAt(0) !== '^') {
        regexStr += '^';
      }

      regexStr += pattern;

      if (pattern.charAt(pattern.length - 1) !== '$') {
        regexStr += '$';
      }

      regex = new RegExp(regexStr);
    } else {
      regexStr = pattern.toString();
      regex = pattern;
    }
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }
      const value: string = control.value;
      return regex.test(value)
        ? null
        : { pattern: { requiredPattern: regexStr, actualValue: value } };
    };
  }

  static isTimeString(): ValidatorFn {
    return this.pattern(TIME_REGEXP);
  }

  static isLocalDateString(): ValidatorFn {
    return this.pattern(LOCAL_DATE_REGEXP);
  }

  static isDateString(): ValidatorFn {
    return this.pattern(DATE_REGEXP);
  }

  static isDateBefore(rootPath: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const root = control.root;
      if (!(root instanceof FormGroup)) {
        // Beim initialisieren ist Root ein FormControl und verweist auf sich selbst
        return null;
      }

      const afterControl = root.get(rootPath);
      if (isEmptyInputValue(afterControl.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const beforeValue = control.value;
      const afterValue = afterControl.value;
      const beforeDateTime = new Date(beforeValue).getTime();
      const afterDateTime = new Date(afterValue).getTime();

      return beforeDateTime <= afterDateTime
        ? null
        : { isDateBefore: { maxDate: afterValue, actualValue: beforeValue } };
    };
  }

  static isDateAfter(rootPath: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const root = control.root;
      if (!(root instanceof FormGroup)) {
        // Beim initialisieren ist Root ein FormControl und verweist auf sich selbst
        return null;
      }

      const beforeControl = root.get(rootPath);
      if (isEmptyInputValue(beforeControl.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const afterValue = control.value;
      const beforeValue = beforeControl.value;
      const afterDateTime = new Date(afterValue).getTime();
      const beforeDateTime = new Date(beforeValue).getTime();

      return afterDateTime >= beforeDateTime
        ? null
        : { isDateAfter: { minDate: beforeValue, actualValue: afterValue } };
    };
  }

  static minLengthArray(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const length: number = control.value ? control.value.length : 0;
      return length < min
        ? { minLengthArray: { requiredLength: min, actualLength: length } }
        : null;
    };
  }

  static maxLengthArray(max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const length: number = control.value ? control.value.length : 0;
      return length > max
        ? { maxLengthArray: { requiredLength: max, actualLength: length } }
        : null;
    };
  }
}
