import { Datei } from '@/@types/datei.interface';

// eslint-disable-next-line
export namespace UploadUtils {
  // eslint-disable-next-line
  export function readFileIfExists(
    fileItem: any,
    onloadCallback: (value: Datei) => void,
  ): Promise<any> {
    // Datei als String einlesen, damit wir sie später verschicken können
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject('Problem parsing input file.');
      };

      fileReader.onload = () => {
        const datei = {
          dataAsBase64Text: fileReader.result as string, // (<string>fileReader.result).split(',')[1],
          name: fileItem.name,
          beschreibung: '',
        };
        onloadCallback(datei);
        resolve(undefined);
      };

      fileReader.readAsDataURL(fileItem);
    });
  }
}
