import {
  ICONS_STARTZIEL_KARTE,
  ICONS_STARTZIEL_OFF,
  ICONS_STARTZIEL_ON,
} from '@/assets';
import { GeoJSONSource, Popup } from 'maplibre-gl';
import { karteService } from './karte.service';
import { Layer } from './layer';

export class StartzielLayer extends Layer {
  legendeImageUrl = ICONS_STARTZIEL_OFF as any;
  legendeImageUrlOn = ICONS_STARTZIEL_ON as any;

  legende = [
    {
      imgUrl: ICONS_STARTZIEL_KARTE as any,
      desc: this.name,
    },
  ];

  static KEY_NAME = 'StartzielLayer';
  static KEYS = {
    SRC: StartzielLayer.KEY_NAME + '_src',
    ICONS: StartzielLayer.KEY_NAME + '_icons',
    MARKER: StartzielLayer.KEY_NAME + '_marker',
    POLYGON: StartzielLayer.KEY_NAME + '_polygon',
    POLYGONFILL: StartzielLayer.KEY_NAME + '_polygonfill',
  };
  eventFunction = {
    click: this.onFeatureClick.bind(this),
    mouseenter: this.onFeatureMouseEnter.bind(this),
    mouseleave: this.onFeatureMouseLeave.bind(this),
  };

  constructor() {
    super('Entscheidungspunkte', undefined, false);
  }

  get routeSource(): GeoJSONSource | null {
    return this.map.getSource(StartzielLayer.KEYS.SRC) as GeoJSONSource;
  }

  async add() {
    this.addEvents();
    try {
      await karteService.loadImage(
        ICONS_STARTZIEL_KARTE as any,
        StartzielLayer.KEYS.MARKER,
      );
    } catch (e) {
      console.error(e);
    }

    this.map.addSource(StartzielLayer.KEYS.SRC, {
      type: 'geojson',
      data: '/geojson/startziel.json',
    });

    this.map.addLayer({
      id: StartzielLayer.KEYS.ICONS,
      type: 'symbol',
      filter: ['==', '$type', 'Point'],
      source: StartzielLayer.KEYS.SRC,
      layout: {
        'text-anchor': 'center',
        'text-offset': [0, 0.9],
        'text-field': ['get', 'stadt'],
        'text-font': ['Open Sans Regular'],
        'text-size': 9,
        'icon-image': StartzielLayer.KEYS.MARKER,
        'icon-size': 0.2,
        'icon-offset': [0, -60],
      },
      paint: {
        'text-color': 'rgba(255, 255, 255, 1)',
        'text-halo-color': 'rgba(83, 86, 90, 1)',
        'text-halo-blur': 0,
        'text-halo-width': 1,
      },
    });

    this.update();
    this.visible = true;
  }

  async addEvents() {
    this.map.on('click', StartzielLayer.KEYS.ICONS, this.eventFunction.click);
    this.map.on(
      'mouseenter',
      StartzielLayer.KEYS.ICONS,
      this.eventFunction.mouseenter,
    );
    this.map.on(
      'mouseleave',
      StartzielLayer.KEYS.ICONS,
      this.eventFunction.mouseleave,
    );
  }

  async removeEvents() {
    this.map.off('click', StartzielLayer.KEYS.ICONS, this.eventFunction.click);
    this.map.off(
      'mouseenter',
      StartzielLayer.KEYS.ICONS,
      this.eventFunction.mouseenter,
    );
    this.map.off(
      'mouseleave',
      StartzielLayer.KEYS.ICONS,
      this.eventFunction.mouseleave,
    );
  }

  onFeatureClick(e: any) {
    const sz = e.features[0].properties;
    new Popup()
      .setLngLat(e.lngLat)
      .setHTML(
        `
          <b>${sz.stadt}</b>
        `,
      )
      .addTo(this.map);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFeatureMouseEnter(e: any) {
    this.map.getCanvas().style.cursor = 'pointer';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFeatureMouseLeave(e: any) {
    this.map.getCanvas().style.cursor = '';
  }

  async remove() {
    this.removeEvents();
    if (this.map.getLayer(StartzielLayer.KEYS.ICONS)) {
      this.map.removeLayer(StartzielLayer.KEYS.ICONS);
    }
    if (this.map.getSource(StartzielLayer.KEYS.SRC)) {
      this.map.removeSource(StartzielLayer.KEYS.SRC);
    }
    this.visible = false;
  }

  async update() {
    if (!this.routeSource) {
      return;
    }
  }
}
