import { ValidatorFn, Validator } from './types';
import { AbstractControl } from './form';

export function normalizeValidator(
  validator: ValidatorFn | Validator,
): ValidatorFn {
  if ((validator as any).validate) {
    return (c: AbstractControl) => (validator as Validator).validate(c);
  } else {
    return validator as ValidatorFn;
  }
}
