import { ICONS_VOLLSPERRUNG_KARTE, ICONS_VOLLSPERRUNG_OFF, ICONS_VOLLSPERRUNG_ON } from '@/assets';
import { Popup } from 'maplibre-gl';
import { tic3VerkehrsmeldungService } from '../tic3-verkehrsmeldung/tic3-verkehrsmeldung.service';
import { karteService } from './karte.service';
import { Layer } from './layer';

export class VollsperrungLayer extends Layer {
  legendeImageUrl = ICONS_VOLLSPERRUNG_OFF as any;
  legendeImageUrlOn = ICONS_VOLLSPERRUNG_ON as any;

  legende = [
    {
      imgUrl: ICONS_VOLLSPERRUNG_KARTE as any,
      desc: this.name,
    },
  ];

  sourcePointLayer = 'tic3_point_db';
  sourceLineLayer = 'tic3_line_db';

  static KEY_NAME = 'VollsperrungLayer';
  static KEYS = {
    ICONS: VollsperrungLayer.KEY_NAME + '_icons',
    MARKER: VollsperrungLayer.KEY_NAME + '_marker',
    LINESTRINGS: VollsperrungLayer.KEY_NAME + '_linestrings',
  };
  eventFunction = {
    click: this.onFeatureClick.bind(this),
    mouseenter: this.onFeatureMouseEnter.bind(this),
    mouseleave: this.onFeatureMouseLeave.bind(this),
  };

  constructor() {
    super('Sperrung');
  }

  async add() {
    this.addEvents();
    try {
      await karteService.loadImage(
        ICONS_VOLLSPERRUNG_KARTE as any,
        VollsperrungLayer.KEYS.MARKER,
      );
    } catch (e) {
      console.error(e);
    }

    this.map.addLayer(
      {
        id: VollsperrungLayer.KEYS.LINESTRINGS,
        type: 'line',
        source: karteService.apiSourceKey,
        "source-layer": this.sourceLineLayer,
        filter: ['==', ['get', 'event_type'], 'Vollsperrung'],
        minzoom: 8,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#FF6A13',
          'line-width': 2,
          'line-offset': [
            'interpolate',
            ['exponential', 1],
            ['zoom'],
            10,
            0,
            11,
            0,
          ],
        },
      },
      this.layerAnchorName,
    );
    this.map.addLayer(
      {
        id: VollsperrungLayer.KEYS.ICONS,
        type: 'symbol',
        source: karteService.apiSourceKey,
        "source-layer": this.sourcePointLayer,
        filter: ['==', ['get', 'event_type'], 'Vollsperrung'],
        layout: {
          'icon-image': VollsperrungLayer.KEYS.MARKER,
          'icon-size': 0.12,
        },
      },
      this.layerAnchorName,
    );
    this.visible = true;
  }

  async addEvents() {
    this.map.on('click', VollsperrungLayer.KEYS.ICONS, this.eventFunction.click);
    this.map.on('mouseenter', VollsperrungLayer.KEYS.ICONS, this.eventFunction.mouseenter);
    this.map.on('mouseleave', VollsperrungLayer.KEYS.ICONS, this.eventFunction.mouseleave);
  }

  async removeEvents() {
    this.map.off('click', VollsperrungLayer.KEYS.ICONS, this.eventFunction.click);
    this.map.off('mouseenter', VollsperrungLayer.KEYS.ICONS, this.eventFunction.mouseenter);
    this.map.off('mouseleave', VollsperrungLayer.KEYS.ICONS, this.eventFunction.mouseleave);
  }

  async onFeatureClick(e: any) {
    const feature = e.features[0];
    const meldung = await tic3VerkehrsmeldungService.fetchOne(
      feature.properties.id,
    );
    if (!meldung) {
      return;
    }
    new Popup({ maxWidth: '400px' })
      .setLngLat(e.lngLat)
      .setHTML(
        `
        <h6>${meldung.lagebeschreibung}</h6>

        <table>
          <tbody>
            ${
              meldung.von
                ? `<tr>
              <th>Von:</th>
              <td>
                ${meldung.von.toLocaleString()}
              </td>
            </tr>`
                : ''
            }
            ${
              meldung.bis
                ? `<tr>
              <th>Bis:</th>
              <td>${meldung.bis.toLocaleString()}</td>
            </tr>`
                : ''
            }
          </tbody>
        </table>
        <p class="mt-1">${meldung.eventbeschreibung}</p>
        `,
      )
      .addTo(this.map);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFeatureMouseEnter(e: any) {
    this.map.getCanvas().style.cursor = 'pointer';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFeatureMouseLeave(e: any) {
    this.map.getCanvas().style.cursor = '';
  }

  async remove() {
    this.removeEvents();
    if (this.map.getLayer(VollsperrungLayer.KEYS.ICONS)) {
      this.map.removeLayer(VollsperrungLayer.KEYS.ICONS);
    }
    if (this.map.getLayer(VollsperrungLayer.KEYS.LINESTRINGS)) {
      this.map.removeLayer(VollsperrungLayer.KEYS.LINESTRINGS);
    }
    this.visible = false;
  }

  async update() {
    return;
  }
}
