
import { Component, Prop, Vue } from "vue-property-decorator";
import { testdatenService } from "@/services/testdaten/testdaten.service";

@Component
export default class TestdatenComponent extends Vue {
  testdatenService = testdatenService;
  // @Prop() testdaten!: Testdaten;

  created() {
    this.updateTestdaten();
  }

  async updateTestdaten() {
    console.log("updateTestdaten");
    await testdatenService.fetchTestdaten();
    // setTimeout(() => this.updateImportStaende(), 1000 * 60);
  }

  async toggleActive(item: any) {
    console.log("toggle active clicked on item:", item.from_to);
    await testdatenService.toggleSegment(item.from_to);
    this.updateTestdaten();
  }
}
