import { LineString, Point } from '@turf/turf';
import { BaustelleProfundInterface } from './baustelle-profund.interface';

const worktypes: any = {};
worktypes['I1'] = 'Instandsetzung auf der Deckschicht';
worktypes['I2'] = 'Instandsetzung an der Deckschicht';
worktypes['E1'] = 'Erneuerung an der Decke';
worktypes['E2'] = 'Erneuerung an der Tragschicht / Oberbau';
worktypes['F'] = 'Anbau von Fahrstreifen';
worktypes['S'] = 'Anbau von Seitenstreifen';
worktypes['Sch'] = 'Schallschutzmaßnahme';
worktypes['U'] = 'Umbau, z.B. neue Verkehrsführung';
worktypes['BRI'] = 'Brückeninstandsetzung';
worktypes['BRU'] = 'Brückenumbau';
worktypes['BRN'] = 'Brückenneubau';
worktypes['TIS'] = 'Tunnelinstandsetzung';
worktypes['TUN'] = 'Tunnelneubau';
worktypes['ICE'] = 'Bauarbeiten durch DB-AG';
worktypes['ENT'] = 'Entwässerungsarbeiten';
worktypes['RP1'] = 'Um- und Ausbauarbeiten';
worktypes['RP2'] = 'Beseitigung Unfallfolgen';
worktypes['RP3'] = 'Brückeninstandsetzungsarbeiten';
worktypes['RP4'] = 'Entwässerungsarbeiten';
worktypes['RP5'] = 'Erneuerung der Fahrbahn';
worktypes['RP6'] = 'Fahrbahninstandsetzung';
worktypes['RP7'] = 'Grünpflege';
worktypes['RP8'] = 'Schallschutzarbeiten';
worktypes['RP9'] = 'Markierungsarbeiten';
worktypes['RP10'] = 'Reinigungsarbeiten';
worktypes['RP11'] = 'Schutzplankenarbeiten';
worktypes['RP12'] = 'Tunnelwartung';
worktypes['RP13'] = 'Neubau einer Anschlußstelle';
worktypes['RP14'] = 'Verbreiterung der Fahrbahn';
worktypes['RP18'] = 'Brückenprüfung';
worktypes['RP19'] = 'Beschilderungsarbeiten';
worktypes['RP20'] = 'Vermessungsarbeiten';
worktypes['RP22'] = 'Brückenerhaltungsarbeiten';
worktypes['RP23'] = 'Brückenneubau';
worktypes['RP24'] = 'Tunnelnachrüstung';
worktypes['RP25'] = 'Tunnelneubau';
worktypes['RP26'] = 'Arbeiten an Mautbrücken';
worktypes['RP27'] = 'Fahrbahninstandhaltung';
worktypes['OK1'] = 'Unterhaltungsarbeiten';
worktypes['OK2'] = 'Bauwerksarbeiten';
worktypes['OK3'] = 'Fahrbahnreparatur';
worktypes['OK4'] = 'Baustelleneinrichtung';
worktypes['OK5'] = 'Ausbau';
worktypes['OK6'] = 'Akute Verkehrsgefährdung';
worktypes['OK99'] = 'Sonstiges';

export class BaustelleProfund {
  linestring: LineString;
  punkt: Point;
  id: string;
  strasse: string;
  laenge: string;
  hoehe: string;
  breite: string;
  startZeit: Date;
  endeZeit: Date;
  startLocation: string;
  startLocationNumber: string;
  endeLocation: string;
  endeLocationNumber: string;
  bundesland: string;
  worktype: string;
  speedlimit: string;

  constructor(baustelleProfundInterface: BaustelleProfundInterface) {
    this.id = baustelleProfundInterface.id;
    this.strasse = baustelleProfundInterface.strasse;
    this.laenge = baustelleProfundInterface.laenge;
    this.hoehe = baustelleProfundInterface.hoehe;
    this.breite = baustelleProfundInterface.breite;
    this.startZeit = new Date(baustelleProfundInterface.startZeit);
    this.endeZeit = new Date(baustelleProfundInterface.endeZeit);
    this.startLocation = baustelleProfundInterface.startLocation;
    this.startLocationNumber = baustelleProfundInterface.startLocationNumber;
    this.endeLocation = baustelleProfundInterface.endeLocation;
    this.endeLocationNumber = baustelleProfundInterface.endeLocationNumber;
    this.bundesland = baustelleProfundInterface.bundesland;
    this.worktype = baustelleProfundInterface.worktype;
    this.speedlimit = baustelleProfundInterface.speedlimit;
    this.linestring = baustelleProfundInterface.linestring;
    this.punkt = baustelleProfundInterface.punkt;
  }

  getFeature() {
    return {
      type: 'Feature',
      geometry: this.punkt,
      properties: {
        id: this.id,
      },
    };
  }

  get translatedWorktype() {
    if (worktypes[this.worktype]) {
      return worktypes[this.worktype];
    }
    return this.worktype;
  }

  get startZeitFormated() {
    return this.getLocalDateString(this.startZeit);
  }

  get endeZeitFormated() {
    return this.getLocalDateString(this.endeZeit);
  }

  getLocalDateString(date: Date) {
    return date.toLocaleString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  }
}
