import { apiService } from '../api/api.service';
import { notificationService } from '../notification/notification.service';
import { Testdaten } from './testdaten.class';
import { TestdatenInterface } from './testdaten.interface';

class TestdatenService {
  private testdaten: Testdaten[] = [];

  private schnittstelle = 'extern/testdaten-reisezeit';

  getTestdaten() {
    return this.testdaten;
  }

  async fetchTestdaten() {
    try {
      this.testdaten = ((await apiService.get(
        this.schnittstelle,
      )) as TestdatenInterface[]).map((b) => new Testdaten(b));
    } catch (e) {
      notificationService.sendErrorNotification(
        'Fehler beim Abfragen der Testdaten',
      );
    }
  }

  async toggleSegment(from_to: String){
    try {
      await apiService.get(
        `${this.schnittstelle}/${from_to}/toggle`,
      );
    } catch (e){
      console.error(e);
      notificationService.sendErrorNotification(
        `Fehler beim Umschalten der Testrecords`,
      );
    }
  }
}

export const testdatenService = new TestdatenService();
