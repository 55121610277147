import { BaustelleMiaInterface } from './baustelle-mia.interface';

export class BaustelleMia {
  public id: string;
  public creationTime?: Date;
  public delayTime?: number;
  public startTime?: Date;
  public endTime?: Date;
  public eventcodes: string;
  public generalPublicComment: string;
  public lage: string;
  public abnormalTrafficType: string;
  public trafficTrendType: string;
  public averageSpeed: number;
  public fromNode: string;
  public toNode: string;
  public openlr?: string;
  public startpunkt: [number, number];
  public endpunkt: [number, number];
  public linestring: Array<Array<[number, number]>>;


  constructor(baustelleMia: BaustelleMiaInterface) {
    this.id = baustelleMia.id;

    if(baustelleMia.creationTime) {
      this.creationTime = new Date(baustelleMia.creationTime);
    }
    if(baustelleMia.delayTime) {
      this.delayTime = baustelleMia.delayTime;
    }
    if(baustelleMia.startTime) {
      this.startTime = new Date(baustelleMia.startTime);
    }
    if(baustelleMia.endTime) {
      this.endTime = new Date(baustelleMia.endTime);
    }

    this.eventcodes = baustelleMia.eventcodes;
    this.generalPublicComment = baustelleMia.generalPublicComment;
    this.lage = baustelleMia.lage;
    this.abnormalTrafficType = baustelleMia.abnormalTrafficType;
    this.trafficTrendType = baustelleMia.trafficTrendType;
    this.averageSpeed = baustelleMia.averageSpeed;
    this.fromNode = baustelleMia.fromNode;
    this.toNode = baustelleMia.toNode;
    if(baustelleMia.openlr){
      this.openlr = baustelleMia.openlr;
    }
    this.startpunkt = baustelleMia.startpunkt;
    this.endpunkt = baustelleMia.endpunkt;
    this.linestring = baustelleMia.linestring;

  }

  getFeatures() {
    const features: any[] = [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: this.startpunkt,
        },
        properties: {
          id: this.id,
        },
      },
    ];
    if (this.linestring) {
      features.push({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: this.linestring,
        },
        properties: {
          id: this.id,
        },
      });
    }
    return features;
  }
}
