import { ICONS_STAU_KARTE, ICONS_STAU_OFF, ICONS_STAU_ON } from '@/assets';
import { Popup } from 'maplibre-gl';
import { tomtomStauobjektService } from '../tomtom-stauobjekt/tomtom-stauobjekt.service';
import { karteService } from './karte.service';
import { Layer } from './layer';

export class StauLayer extends Layer {
  legendeImageUrl = ICONS_STAU_OFF as any;
  legendeImageUrlOn = ICONS_STAU_ON as any;

  legende = [];

  sourcePointLayer = 'stau_point_db';
  sourceLineLayer = 'stau_line_db';

  static KEY_NAME = 'StauLayer';
  static KEYS = {
    ICONS: StauLayer.KEY_NAME + '_icons',
    MARKER: StauLayer.KEY_NAME + '_marker',
    LINESTRINGS: StauLayer.KEY_NAME + '_linestrings',
  };
  eventFunction = {
    click: this.onFeatureClick.bind(this),
    mouseenter: this.onFeatureMouseEnter.bind(this),
    mouseleave: this.onFeatureMouseLeave.bind(this),
  };

  constructor() {
    super('Stau');
  }

  async add() {
    this.addEvents();

    try {
      await karteService.loadImage(
        ICONS_STAU_KARTE as any,
        StauLayer.KEYS.MARKER,
      );
    } catch (e) {
      console.error(e);
    }

    this.map.addLayer(
      {
        id: StauLayer.KEYS.LINESTRINGS,
        type: 'line',
        'source-layer': this.sourceLineLayer,
        source: karteService.apiSourceKey,
        minzoom: 8,
        layout: {
          'line-join': 'miter',
        },
        paint: {
          'line-color': '#FF6A13',
          'line-width': 2,
          'line-offset': [
            'interpolate',
            ['exponential', 1],
            ['zoom'],
            10,
            2,
            11,
            4,
          ],
        },
      },
      this.layerAnchorName,
    );
    this.map.addLayer(
      {
        id: StauLayer.KEYS.ICONS,
        type: 'symbol',
        source: karteService.apiSourceKey,
        'source-layer': this.sourcePointLayer,
        layout: {
          'icon-image': StauLayer.KEYS.MARKER,
          // 'icon-size': 0.15,
          'icon-size': ["interpolate", ["linear"], ["zoom"], 7, 0.1, 15, 0.3],
        },
      },
      this.layerAnchorName,
    );
    this.visible = true;
  }

  async addEvents() {
    this.map.on('click', StauLayer.KEYS.ICONS, this.eventFunction.click);
    this.map.on(
      'mouseenter',
      StauLayer.KEYS.ICONS,
      this.eventFunction.mouseenter,
    );
    this.map.on(
      'mouseleave',
      StauLayer.KEYS.ICONS,
      this.eventFunction.mouseleave,
    );
  }

  async removeEvents() {
    this.map.off('click', StauLayer.KEYS.ICONS, this.eventFunction.click);
    this.map.off(
      'mouseenter',
      StauLayer.KEYS.ICONS,
      this.eventFunction.mouseenter,
    );
    this.map.off(
      'mouseleave',
      StauLayer.KEYS.ICONS,
      this.eventFunction.mouseleave,
    );
  }

  async onFeatureClick(e: any) {
    const feature = e.features[0];
    const meldung = await tomtomStauobjektService.fetchOne(
      feature.properties.id,
    );
    if (!meldung) {
      return;
    }
    let delayTimeMinutes = '';
    if (meldung.delayTime) {
      delayTimeMinutes = Math.round(meldung.delayTime / 60).toString() + ' min';
    }
    let typ = '';
    if (meldung.abnormalTrafficType) {
      if (meldung.abnormalTrafficType == 'stationaryTraffic') {
        typ = 'Stau';
      }
    }
    new Popup({ maxWidth: '400px' })
      .setLngLat(e.lngLat)
      .setHTML(
        `
        <h6>${meldung.fromNode} - ${meldung.toNode}</h6>

        <table>
          <tbody>
    
            ${
              meldung.startTime
                ? `<tr>
                  <th>Seit:</th>
                  <td>
                    ${meldung.startTime.toLocaleString()}
                  </td>
                </tr>`
                : ''
            }
    
            <tr>
              <th>Typ:</th>
              <td>` +
          typ +
          `</td>
            </tr>

            <tr>
              <th>&Oslash; Geschwindigkeit:</th>
              <td>${meldung.averageSpeed} km/h</td>
            </tr>

            <tr>
              <th>Zeitverlust:</th>
              <td>` +
          delayTimeMinutes +
          `</td>
            </tr>


          </tbody>
        </table>
        `,
      )
      .addTo(this.map);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFeatureMouseEnter(e: any) {
    this.map.getCanvas().style.cursor = 'pointer';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFeatureMouseLeave(e: any) {
    this.map.getCanvas().style.cursor = '';
  }

  async remove() {
    this.removeEvents();
    if (this.map.getLayer(StauLayer.KEYS.ICONS)) {
      this.map.removeLayer(StauLayer.KEYS.ICONS);
    }
    if (this.map.getLayer(StauLayer.KEYS.LINESTRINGS)) {
      this.map.removeLayer(StauLayer.KEYS.LINESTRINGS);
    }
    this.visible = false;
  }

  async update() {
    return;
  }
}
