import {
  ICONS_DWISTA_KARTE,
  ICONS_DWISTA_KARTE_KREIS,
  ICONS_DWISTA_OFF,
  ICONS_DWISTA_ON,
} from "@/assets";
import { Popup } from "maplibre-gl";
import { nbaService } from "../nba/nba.service";
import { karteService } from "./karte.service";
import { Layer } from "./layer";

export class NbaLayer extends Layer {
  legendeImageUrl = ICONS_DWISTA_OFF as any;
  legendeImageUrlOn = ICONS_DWISTA_ON as any;

  openPopups: string[] = [];

  legende = [
    {
      imgUrl: ICONS_DWISTA_KARTE as any,
      desc: this.name,
    },
  ];

  sourceLayer = "nba_db";

  static KEY_NAME = "NbaLayer";
  static KEYS = {
    ICONS: NbaLayer.KEY_NAME + "_icons",
    ICONS_NOIMAGE: NbaLayer.KEY_NAME + "_icons_no_image",
    MARKER: NbaLayer.KEY_NAME + "_marker",
    MARKER_KREIS: NbaLayer.KEY_NAME + "_marker_mit_kreis",
  };
  eventFunction = {
    click: this.onFeatureClick.bind(this),
    mouseenter: this.onFeatureMouseEnter.bind(this),
    mouseleave: this.onFeatureMouseLeave.bind(this),
  };

  constructor() {
    super("dWiSta");
  }

  async add() {
    this.addEvents();
    try {
      await karteService.loadImage(
        ICONS_DWISTA_KARTE as any,
        NbaLayer.KEYS.MARKER
      );
    } catch (e) {
      console.error(e);
    }
    try {
      await karteService.loadImage(
        ICONS_DWISTA_KARTE_KREIS as any,
        NbaLayer.KEYS.MARKER_KREIS
      );
    } catch (e) {
      console.error(e);
    }

    this.map.addLayer(
      {
        id: NbaLayer.KEYS.ICONS_NOIMAGE,
        type: "symbol",
        source: karteService.apiSourceKey,
        "source-layer": this.sourceLayer,
        filter: ["!=", ["get", "verkehrszentrale"], "Deutschland (Hessen)"],
        layout: {
          "icon-image": NbaLayer.KEYS.MARKER,
          "icon-size": 0.15,
        },
        paint: {
          "text-color": "rgb(0, 88, 184)",
        },
      },
      this.layerAnchorName
    );

    this.map.addLayer(
      {
        id: NbaLayer.KEYS.ICONS,
        type: "symbol",
        source: karteService.apiSourceKey,
        "source-layer": this.sourceLayer,
        filter: ["==", ["get", "verkehrszentrale"], "Deutschland (Hessen)"],
        layout: {
          "text-anchor": "left",
          "text-field": "",
          "text-font": ["Noto Sans Bold"],
          "text-size": 29,
          "icon-image": NbaLayer.KEYS.MARKER_KREIS,
          "icon-size": 0.15,
        },
        paint: {
          "text-color": "rgb(0, 88, 184)",
        },
      },
      this.layerAnchorName
    );

    this.visible = true;
  }

  async addEvents() {
    this.map.on("click", NbaLayer.KEYS.ICONS, this.eventFunction.click);
    this.map.on("click", NbaLayer.KEYS.ICONS_NOIMAGE, this.eventFunction.click);
    this.map.on(
      "mouseenter",
      NbaLayer.KEYS.ICONS,
      this.eventFunction.mouseenter
    );
    this.map.on(
      "mouseenter",
      NbaLayer.KEYS.ICONS_NOIMAGE,
      this.eventFunction.mouseenter
    );
    this.map.on(
      "mouseleave",
      NbaLayer.KEYS.ICONS,
      this.eventFunction.mouseleave
    );
    this.map.on(
      "mouseleave",
      NbaLayer.KEYS.ICONS_NOIMAGE,
      this.eventFunction.mouseleave
    );
  }

  async removeEvents() {
    this.map.off("click", NbaLayer.KEYS.ICONS, this.eventFunction.click);
    this.map.off(
      "click",
      NbaLayer.KEYS.ICONS_NOIMAGE,
      this.eventFunction.click
    );
    this.map.off(
      "mouseenter",
      NbaLayer.KEYS.ICONS,
      this.eventFunction.mouseenter
    );
    this.map.off(
      "mouseenter",
      NbaLayer.KEYS.ICONS_NOIMAGE,
      this.eventFunction.mouseenter
    );
    this.map.off(
      "mouseleave",
      NbaLayer.KEYS.ICONS,
      this.eventFunction.mouseleave
    );
    this.map.off(
      "mouseleave",
      NbaLayer.KEYS.ICONS_NOIMAGE,
      this.eventFunction.mouseleave
    );
  }

  async onFeatureClick(e: any) {
    const feature = e.features[0];

    // console.log("feature id", feature.properties.id);
    if (this.openPopups.includes(feature.properties.id)) {
      // console.log("popup already open ");
      return;
    } else {
      // console.log("popup not open - add id to list");
      this.openPopups.push(feature.properties.id);
    }

    const nba = await nbaService.fetchOne(feature.properties.id);
    if (!nba) {
      return;
    }

    let imageline = "";
    if (nba.url.includes("http")) {
      imageline = `<img class="w-100" src="${nba.url}${
        nba.url.includes("?") ? "" : "?"
      }&q=${new Date().getTime()}">`;
    }

    const popup = new Popup({ maxWidth: "360px", closeOnClick: false })
      .setLngLat(e.lngLat)
      .setHTML(
        `
          <div style="display: flex; height:40px; min-width:200px;">

            <div style="flex=1;">
              <span style="max-width:50px; background:#1a70b8; color:#fff; text-align:center; padding: 3px 10px; font-weight: 700;">
              ${nba.strasse}
              </span>
            </div>
          
            <div style="flex:1; display:flex; flex-direction:column;">
              <div style="flex:1; font-weight:700; padding-left:5px; vertical-align:text-top;">
              ${nba.anlagenbezeichnung}
              </div>

              <div style="flex: 1; padding-left:5px; vertical-align: text-top;">
              Fahrtrichtung ${nba.richtung}
              </div>
            </div>
          </div>
          ${imageline}<br>
          ${nba.typ} ${nba.anlagenkennung} | 
          ${nba.verkehrszentrale}
        `
      );

    popup.addTo(this.map);

    popup.on('close', ()=>{
      // console.log("close popup mit id ", feature.properties.id);
      const index = this.openPopups.indexOf(feature.properties.id);
      if (index < 0) {
        return; // nicht in offene Popups vorhanden
      }
      this.openPopups.splice(index , 1);
    })

    // console.log(popup);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFeatureMouseEnter(e: any) {
    this.map.getCanvas().style.cursor = "pointer";
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFeatureMouseLeave(e: any) {
    this.map.getCanvas().style.cursor = "";
  }

  async remove() {
    this.removeEvents();
    if (this.map.getLayer(NbaLayer.KEYS.ICONS)) {
      this.map.removeLayer(NbaLayer.KEYS.ICONS);
    }
    if (this.map.getLayer(NbaLayer.KEYS.ICONS_NOIMAGE)) {
      this.map.removeLayer(NbaLayer.KEYS.ICONS_NOIMAGE);
    }
    this.visible = false;
  }

  async update() {
    return;
  }
}
