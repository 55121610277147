import { apiService } from '../api/api.service';
import { notificationService } from '../notification/notification.service';
import { TomtomStauobjekt } from './tomtom-stauobjekt.class';
import { TomtomStauobjektInterface } from './tomtom-stauobjekt.interface';

class TomtomStauobjektService {
  private tomtomStauobjekte: TomtomStauobjekt[] = [];
  private tomtomStaus: TomtomStauobjekt[] = [];

  private schnittstelle = 'extern/tomtom-stauobjekte';

  async fetchAll() {
    try {
      this.tomtomStauobjekte = ((await apiService.get(
        this.schnittstelle,
      )) as TomtomStauobjektInterface[]).map((r) => new TomtomStauobjekt(r));
    } catch (e) {
      notificationService.sendErrorNotification(
        'Fehler beim Abfragen der TomTom Stauobjekte',
      );
    }
  }

  getAll() {
    return this.tomtomStauobjekte;
  }

  async fetchAllStaus() {
    // console.log('tomtom-stauobjekt.service: fetchAllStaus()');
    // MW 2021-08-10: auskommentiert, da das das update verhindert
    // if (this.tomtomStaus.length !== 0) {
    //   return;
    // }
    try {
      // console.log(
      //   'fetch-url: ',
      //   this.schnittstelle + '/stau' + '?' + new Date().getTime(),
      // );
      // cache-buster
      this.tomtomStaus = ((await apiService.get(
        this.schnittstelle + '/stau' + '?' + new Date().getTime(),
      )) as TomtomStauobjektInterface[]).map((r) => new TomtomStauobjekt(r));
    } catch (e) {
      notificationService.sendErrorNotification(
        'Fehler beim Abfragen der Tomtom Stauobjekte',
      );
    }
  }

  getStaus() {
    return this.tomtomStaus;
  }

  getStausFeatures() {
    return this.getStaus().reduce((features, stau) => {
      features.push(...stau.getFeatures());
      return features;
    }, [] as any[]);
  }

  findStauById(id: string) {
    return this.getStaus().find((stau) => stau.id === id);
  }

  async fetchOne(id: string) {
    try {
      return new TomtomStauobjekt(
        (await apiService.get(
          this.schnittstelle + '/' + encodeURIComponent(id),
        )) as TomtomStauobjektInterface,
      );
    } catch (e) {
      notificationService.sendErrorNotification(
        `Fehler beim Abfragen des TomTom Stauobjekts (${id})`,
      );
    }
  }
}

export const tomtomStauobjektService = new TomtomStauobjektService();
