import { ICONS_KORRIDORE_OFF, ICONS_KORRIDORE_ON } from "@/assets";
import { LineString } from "@turf/turf";
import { routeService } from "../route/route.service";
import { Route } from "../route/route.class";
import { Layer } from "./layer";
import {
  ExpressionSpecification,
  FilterSpecification,
  LngLatBounds,
  SymbolLayoutArray,
} from "maplibre-gl";
import { karteService } from "./karte.service";
import { Korridor } from "../korridor/korridor.class";
import { Richtung } from "../richtung/richtung.class";

export class RouteLayer extends Layer {
  legendeImageUrl = ICONS_KORRIDORE_OFF as any;
  legendeImageUrlOn = ICONS_KORRIDORE_ON as any;

  legende = [];

  sourceLayer = "routen_db";
  sourceCenterLayer = "routen_center_db";

  routeKey = "route";
  routeAktivKey = "route-aktiv";
  routeHauptrouteKey = "route-hauptroute";
  routeHauptrouteAktivKey = "route-hauptroute-aktiv";
  routeStartEndeMarker = "route-start-ende-image";
  routeHighlightKey = "route-highlight";
  routeHighlightAktivKey = "route-highlight-aktiv";
  routeHighlightTravelTimeKeyOben = "route-highlight-traveltime-360";
  routeHighlightTravelTimeKeyUnten = "route-highlight-traveltime-180";
  routeHighlightTravelTimeKeyRechts = "route-highlight-traveltime-90";
  routeHighlightTravelTimeKeyLinks = "route-highlight-traveltime-270";
  routePopupIconOben = "route-popup-icon-360";
  routePopupIconUnten = "route-popup-icon-180";
  routePopupIconRechts = "route-popup-icon-90";
  routePopupIconLinks = "route-popup-icon-270";

  filter = {
    // filter = {
    isHauptroute: [
      "==",
      ["get", "is_hauptroute"],
      true,
    ] as ExpressionSpecification,
    isNotHauptroute: [
      "!=",
      ["get", "is_hauptroute"],
      true,
    ] as ExpressionSpecification,
    isAktiv: ["==", ["get", "is_aktiv"], true] as ExpressionSpecification,
    isNotAktiv: ["!=", ["get", "is_aktiv"], true] as ExpressionSpecification,
    isPopupOben: [
      "any",
      ["==", ["get", "popup"], 360],
      ["==", ["get", "popup"], 0],
    ] as ExpressionSpecification,
    isPopupRechts: ["==", ["get", "popup"], 90] as ExpressionSpecification,
    isPopupUnten: ["==", ["get", "popup"], 180] as ExpressionSpecification,
    isPopupLinks: ["==", ["get", "popup"], 270] as ExpressionSpecification,
  };

  filterLayer = {
    // filterLayer: {[key: string]: () => ExpressionSpecification} = {
    [this.routeKey]: () => {
      return [
        "all",
        this.filter.isNotHauptroute,
        this.getKorridorIdFilter(),
      ] as ExpressionSpecification;
    },
    [this.routeHauptrouteKey]: () => {
      return [
        "all",
        this.filter.isHauptroute,
        this.getKorridorIdFilter(),
      ] as ExpressionSpecification;
    },
    [this.routeHauptrouteAktivKey]: () => {
      return [
        "all",
        this.filter.isAktiv,
        this.filter.isHauptroute,
        this.getKorridorIdFilter(),
      ] as ExpressionSpecification;
    },
    [this.routeAktivKey]: () => {
      return [
        "all",
        this.filter.isAktiv,
        this.filter.isNotHauptroute,
        this.getKorridorIdFilter(),
      ] as ExpressionSpecification;
    },
    [this.routeHighlightKey]: () => {
      return [
        "all",
        this.filter.isNotAktiv,
        this.isHighlightedRichtungFilter(),
      ] as ExpressionSpecification;
    },
    [this.routeHighlightAktivKey]: () => {
      return [
        "all",
        this.filter.isAktiv,
        this.isHighlightedRichtungFilter(),
      ] as ExpressionSpecification;
    },
    [this.routeHighlightTravelTimeKeyOben]: () => {
      return [
        "all",
        this.filter.isPopupOben,
        this.isHighlightedRichtungFilter(),
      ] as ExpressionSpecification;
    },
    [this.routeHighlightTravelTimeKeyUnten]: () => {
      return [
        "all",
        this.filter.isPopupUnten,
        this.isHighlightedRichtungFilter(),
      ] as ExpressionSpecification;
    },
    [this.routeHighlightTravelTimeKeyRechts]: () => {
      return [
        "all",
        this.filter.isPopupRechts,
        this.isHighlightedRichtungFilter(),
      ] as ExpressionSpecification;
    },
    [this.routeHighlightTravelTimeKeyLinks]: () => {
      return [
        "all",
        this.filter.isPopupLinks,
        this.isHighlightedRichtungFilter(),
      ] as ExpressionSpecification;
    },
  };

  visibleKorridore: number[] = [];
  highlightedRichtungen: number[] = [];

  constructor() {
    super("Korridore", "place_label_other");
  }

  onRouteClicked: ((route: Route) => void) | null = null;

  async add() {
    this.addEvents();

    try {
      await karteService.loadImage(
        "/images/vrz-wuerfel.png",
        this.routeStartEndeMarker
      );
    } catch (e) {
      console.error(e);
    }

    try {
      await karteService.loadImage(
        "/images/popup_360.png",
        this.routePopupIconOben,
        {
          stretchX: [
            [25, 55],
            [85, 115],
          ],
          stretchY: [[25, 100]],
          content: [25, 25, 115, 100],
          pixelRatio: 2,
        }
      );
    } catch (e) {
      console.error(e);
    }

    try {
      await karteService.loadImage(
        "/images/popup_180.png",
        this.routePopupIconUnten,
        {
          stretchX: [
            [25, 55],
            [85, 115],
          ],
          stretchY: [[40, 115]],
          content: [20, 40, 115, 115],
          pixelRatio: 2,
        }
      );
    } catch (e) {
      console.error(e);
    }

    try {
      await karteService.loadImage(
        "/images/popup_90.png",
        this.routePopupIconRechts,
        {
          stretchX: [[40, 115]],
          stretchY: [
            [25, 55],
            [85, 115],
          ],
          content: [40, 25, 115, 115],
          pixelRatio: 2,
        }
      );
    } catch (e) {
      console.error(e);
    }

    try {
      await karteService.loadImage(
        "/images/popup_270.png",
        this.routePopupIconLinks,
        {
          stretchX: [[40, 115]],
          stretchY: [
            [25, 55],
            [85, 115],
          ],
          content: [25, 25, 100, 115],
          pixelRatio: 2,
        }
      );
    } catch (e) {
      console.error(e);
    }

    this.map.addLayer(
      {
        id: this.routeKey,
        type: "line",
        source: karteService.apiSourceKey,
        "source-layer": this.sourceLayer,
        filter: this.filterLayer[this.routeKey](),
        layout: {
          "line-join": "miter",
        },
        paint: {
          "line-color": "#97999C",
          "line-width": 2,
          "line-offset": [
            "interpolate",
            ["exponential", 1],
            ["zoom"],
            10,
            3,
            11,
            5,
          ],
        },
      },
      this.layerAnchorName
    );

    this.map.addLayer(
      {
        id: this.routeHauptrouteKey,
        type: "line",
        source: karteService.apiSourceKey,
        "source-layer": this.sourceLayer,
        filter: this.filterLayer[this.routeHauptrouteKey](),
        layout: {
          "line-join": "miter",
        },
        paint: {
          "line-color": "#FFFFFF",
          "line-width": 2,
          "line-offset": [
            "interpolate",
            ["exponential", 1],
            ["zoom"],
            10,
            3,
            11,
            5,
          ],
        },
      },
      this.layerAnchorName
    );

    this.map.addLayer(
      {
        id: this.routeHauptrouteAktivKey,
        type: "line",
        source: karteService.apiSourceKey,
        "source-layer": this.sourceLayer,
        filter: this.filterLayer[this.routeHauptrouteAktivKey](),
        layout: {
          "line-join": "miter",
        },
        paint: {
          "line-color": "#FFFFFF",
          "line-width": 2,
          "line-offset": [
            "interpolate",
            ["exponential", 1],
            ["zoom"],
            10,
            3,
            11,
            5,
          ],
        },
      },
      this.layerAnchorName
    );

    this.map.addLayer(
      {
        id: this.routeAktivKey,
        type: "line",
        source: karteService.apiSourceKey,
        "source-layer": this.sourceLayer,
        filter: this.filterLayer[this.routeAktivKey](),
        layout: {
          "line-join": "miter",
        },
        paint: {
          "line-color": "#479dff",
          "line-width": 2,
          "line-offset": [
            "interpolate",
            ["exponential", 1],
            ["zoom"],
            10,
            3,
            11,
            5,
          ],
        },
      },
      this.layerAnchorName
    );

    this.map.addLayer(
      {
        id: this.routeHighlightKey,
        type: "line",
        source: karteService.apiSourceKey,
        "source-layer": this.sourceLayer,
        filter: this.filterLayer[this.routeHighlightKey](),
        layout: {
          "line-join": "miter",
        },
        paint: {
          "line-color": "#FF6A13",
          "line-dasharray": [2, 2],
          "line-width": 2,
          "line-offset": [
            "interpolate",
            ["exponential", 1],
            ["zoom"],
            10,
            3,
            11,
            5,
          ],
        },
      },
      this.layerAnchorName
    );

    this.map.addLayer(
      {
        id: this.routeHighlightAktivKey,
        type: "line",
        source: karteService.apiSourceKey,
        "source-layer": this.sourceLayer,
        filter: this.filterLayer[this.routeHighlightAktivKey](),
        layout: {
          "line-join": "miter",
        },
        paint: {
          "line-color": "#FF6A13",
          "line-width": 2,
          "line-offset": [
            "interpolate",
            ["exponential", 1],
            ["zoom"],
            10,
            3,
            11,
            5,
          ],
        },
      },
      this.layerAnchorName
    );

    const reisezeitLayout: any = {
      // const reisezeitLayout: mapboxgl.SymbolLayout = {
      "text-rotation-alignment": "viewport",
      "text-field": "{ttm}",
      "text-font": ["Open Sans Regular"],
      "text-size": 10,
      "text-allow-overlap": true,
      "icon-allow-overlap": true,
      "text-ignore-placement": true,
      "icon-ignore-placement": true,
      "icon-text-fit": "both",
    };
    const reisezeitPaint = {
      "text-color": "#000000",
    };
    this.map.addLayer(
      {
        id: this.routeHighlightTravelTimeKeyOben,
        type: "symbol",
        source: karteService.apiSourceKey,
        "source-layer": this.sourceCenterLayer,
        filter: this.filterLayer[this.routeHighlightTravelTimeKeyOben](),
        layout: {
          ...reisezeitLayout,
          "icon-image": this.routePopupIconOben,
        },
        paint: {
          ...reisezeitPaint,
          "icon-translate": [0, -30],
          "text-translate": [0, -30],
        },
      }
      // this.layerAnchorName,
    );

    this.map.addLayer(
      {
        id: this.routeHighlightTravelTimeKeyUnten,
        type: "symbol",
        source: karteService.apiSourceKey,
        "source-layer": this.sourceCenterLayer,
        filter: this.filterLayer[this.routeHighlightTravelTimeKeyUnten](),
        layout: {
          ...reisezeitLayout,
          "icon-image": this.routePopupIconUnten,
        },
        paint: {
          ...reisezeitPaint,
          "icon-translate": [0, 30],
          "text-translate": [0, 30],
        },
      }
      // this.layerAnchorName,
    );

    this.map.addLayer(
      {
        id: this.routeHighlightTravelTimeKeyLinks,
        type: "symbol",
        source: karteService.apiSourceKey,
        "source-layer": this.sourceCenterLayer,
        filter: this.filterLayer[this.routeHighlightTravelTimeKeyLinks](),
        layout: {
          ...reisezeitLayout,
          "icon-image": this.routePopupIconLinks,
        },
        paint: {
          ...reisezeitPaint,
          "icon-translate": [-40, 0],
          "text-translate": [-40, 0],
        },
      }
      // this.layerAnchorName,
    );

    this.map.addLayer(
      {
        id: this.routeHighlightTravelTimeKeyRechts,
        type: "symbol",
        source: karteService.apiSourceKey,
        "source-layer": this.sourceCenterLayer,
        filter: this.filterLayer[this.routeHighlightTravelTimeKeyRechts](),
        layout: {
          ...reisezeitLayout,
          "icon-image": this.routePopupIconRechts,
        },
        paint: {
          ...reisezeitPaint,
          "icon-translate": [40, 0],
          "text-translate": [40, 0],
        },
      }
      // this.layerAnchorName,
    );

    this.visible = true;
  }

  async addEvents() {
    this.map.on("click", this.routeKey, (e: any) => this.onRouteClick(e));
    this.map.on("mouseenter", this.routeKey, (e: any) =>
      this.onRouteMouseEnter(e)
    );
    this.map.on("mouseleave", this.routeKey, (e: any) =>
      this.onRouteMouseLeave(e)
    );
  }

  async removeEvents() {
    this.map.off("click", this.routeKey, (e: any) => this.onRouteClick(e));
    this.map.off("mouseenter", this.routeKey, (e: any) =>
      this.onRouteMouseEnter(e)
    );
    this.map.off("mouseleave", this.routeKey, (e: any) =>
      this.onRouteMouseLeave(e)
    );
  }

  onRouteClick(e: any) {
    if (this.onRouteClicked) {
      const route = routeService.findById(e.features[0].properties.id);
      if (!route) {
        return;
      }
      this.onRouteClicked(route);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRouteMouseEnter(e: any) {
    this.map.getCanvas().style.cursor = "pointer";
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRouteMouseLeave(e: any) {
    this.map.getCanvas().style.cursor = "";
  }

  async remove() {
    this.removeEvents();
    this.map.removeLayer(this.routeHighlightTravelTimeKeyOben);
    this.map.removeLayer(this.routeHighlightTravelTimeKeyUnten);
    this.map.removeLayer(this.routeHighlightTravelTimeKeyRechts);
    this.map.removeLayer(this.routeHighlightTravelTimeKeyLinks);
    this.map.removeLayer(this.routeHighlightKey);
    this.map.removeLayer(this.routeHighlightAktivKey);
    this.map.removeLayer(this.routeHauptrouteAktivKey);
    this.map.removeLayer(this.routeHauptrouteKey);
    this.map.removeLayer(this.routeAktivKey);
    this.map.removeLayer(this.routeKey);

    this.visible = false;
  }

  updateRoutenLayer() {
    return;
  }

  zoomAufRoute(route: Route) {
    const features = this.map.querySourceFeatures(this.routeKey, {
      sourceLayer: this.sourceLayer,
      filter: ["==", ["get", "id"], route.id],
    });
    if (features.length === 0) {
      return;
    }
    const coordinates = (features[0].geometry as LineString).coordinates;
    const bounds = (features[0].geometry as LineString).coordinates.reduce(
      (bounds, coord) => bounds.extend(coord as any),
      new LngLatBounds(coordinates[0] as any, coordinates[0] as any)
    );
    this.map.fitBounds(bounds, {
      padding: 15,
    });
  }

  async update() {
    return;
  }

  isKorridorVisible(korridor: Korridor) {
    return this.visibleKorridore.includes(korridor.id);
  }

  toggleKorridorVisibility(korridor: Korridor) {
    if (this.isKorridorVisible(korridor)) {
      const index = this.visibleKorridore.indexOf(korridor.id);
      this.visibleKorridore.splice(index, 1);
    } else {
      this.visibleKorridore.push(korridor.id);
    }
    this.updateFilter();
  }

  getKorridorIdFilter() {
    return [
      "in",
      ["get", "korridor_id"],
      ["literal", this.visibleKorridore.concat()],
    ] as ExpressionSpecification;
  }

  isRichtungHighlighted(richtung: Richtung) {
    return this.highlightedRichtungen.includes(richtung.id);
  }

  toggleRichtungHighlight(richtung: Richtung) {
    if (this.isRichtungHighlighted(richtung)) {
      const index = this.highlightedRichtungen.indexOf(richtung.id);
      this.highlightedRichtungen.splice(index, 1);
    } else {
      this.highlightedRichtungen.push(richtung.id);
    }
    this.updateFilter();
  }

  isHighlightedRichtungFilter() {
    return [
      "in",
      ["get", "richtung_id"],
      ["literal", this.highlightedRichtungen.concat()],
    ] as ExpressionSpecification;
  }

  isNotHighlightedRichtungFilter() {
    return ["!", this.isHighlightedRichtungFilter()];
  }

  private updateFilter() {
    Object.keys(this.filterLayer).forEach((layerId) => {
      this.map.setFilter(
        layerId,
        this.filterLayer[layerId]() as FilterSpecification
      );
    });
  }
}
