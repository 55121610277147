import { featureCollection } from '@turf/turf';
import { apiService } from '../api/api.service';
import { notificationService } from '../notification/notification.service';
import { Dwista } from './dwista.class';
import { DwistaInterface } from './dwista.interface';
import { configService } from '@/config/config.service';

class DwistaService {
  private dwistas: Dwista[] = [];

  private schnittstelle = 'extern/dwista';

  getDwistas() {
    return this.dwistas;
  }

  getFeatureCollection() {
    return featureCollection(this.dwistas.map((b) => b.getFeature()) as any);
  }

  findById(id: string) {
    return this.dwistas.find((b) => b.id === id);
  }

  getImageUrl(dwista: Dwista) {
    return configService.getApiUrl() + this.schnittstelle + '/image/' + dwista.url;
  }

  async fetchDwistas() {
    try {
      this.dwistas = ((await apiService.get(
        this.schnittstelle,
      )) as DwistaInterface[]).map((b) => new Dwista(b));
    } catch (e) {
      notificationService.sendErrorNotification(
        'Fehler beim Abfragen der dWiStas',
      );
    }
  }
}

export const dwistaService = new DwistaService();
