// tslint:disable: no-var-requires
export const ICONS_LOGO_AUTOBAHN = require('./icons/logo_autobahn_white.svg');
export const ICONS_SLIDE_IN = require('./icons/slide_in.svg');
export const ICONS_SLIDE_IN_RIGHT = require('./icons/slide_in_right.svg');
export const ICONS_SLIDE_OUT = require('./icons/slide_out.svg');
export const ICONS_SLIDE_OUT_RIGHT = require('./icons/slide_out_right.svg');
export const ICONS_HAUPTROUTE = require('./icons/hauptroute.svg');
export const ICONS_ALTERNATIVROUTE = require('./icons/alternativroute.svg');

export const ICONS_STAU_OFF = require('./icons/Stau_off.svg');
export const ICONS_STAU_ON = require('./icons/Stau_on.svg');
export const ICONS_STAU_KARTE = require('./icons/stvo-124-stau.png');
export const ICONS_STAU_CLUSTER = require('./icons/stau_cluster.png');

export const ICONS_BAUSTELLE_NAVI = require('./icons/baustelle_navi.svg');
export const ICONS_BAUSTELLE_NAVI_ON = require('./icons/baustelle_navi_on.svg');
export const ICONS_BAUSTELLE_KARTE = require('./icons/dreieck_baustelle.png');

export const ICONS_BAUSTELLE_MIA_NAVI = require('./icons/baustelle_navi.svg');
export const ICONS_BAUSTELLE_MIA_NAVI_ON = require('./icons/baustelle_navi_on.svg');
export const ICONS_BAUSTELLE_MIA_KARTE = require('./icons/stvo-123-arbeitsstelle.png');
export const ICONS_BAUSTELLE_SHORT_TERM_MIA_KARTE = require('./icons/warnkegel.png');

export const ICONS_VOLLSPERRUNG_OFF = require('./icons/vollsperrung_off.svg');
export const ICONS_VOLLSPERRUNG_ON = require('./icons/vollsperrung_on.svg');
export const ICONS_VOLLSPERRUNG_KARTE = require('./icons/vollsperrung karte.png');

export const ICONS_DWISTA_OFF = require('./icons/dWista-off.svg');
export const ICONS_DWISTA_ON = require('./icons/dWista-on.svg');
export const ICONS_DWISTA_KARTE = require('./icons/deWista Karte.png');
export const ICONS_DWISTA_KARTE_KREIS = require('./icons/deWista Karte_MitKreis.png');

export const ICONS_KAMERA_OFF = require('./icons/webcam_off.svg');
export const ICONS_KAMERA_ON = require('./icons/webcam_on.svg');
export const ICONS_KAMERA_KARTE = require('./icons/Kamera karte.png');

export const ICONS_VZ_OFF = require('./icons/VZ.svg');
export const ICONS_VZ_ON = require('./icons/VZ_on.svg');
export const ICONS_VZ_KARTE = require('./icons/Vz_Karte.png');

export const ICONS_STARTZIEL_OFF = require('./icons/Hauptpunkt.svg');
export const ICONS_STARTZIEL_ON = require('./icons/Hauptpunkt_on.svg');
export const ICONS_STARTZIEL_KARTE = require('./icons/Hauptpunkt.png');

export const ICONS_LOS_OFF = require('./icons/verkehrslage_off.svg');
export const ICONS_LOS_ON = require('./icons/verkehrslage_on.svg');

export const ICONS_DEFAULT_OFF = require('./icons/los_off.svg');
export const ICONS_DEFAULT_ON = require('./icons/los_on.svg');

export const ICONS_KORRIDORE_OFF = require('./icons/korridore_off.svg');
export const ICONS_KORRIDORE_ON = require( './icons/korridore_on.svg');

// tslint:enable: no-var-requires
