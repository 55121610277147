import {
  ICONS_BAUSTELLE_MIA_KARTE,
  ICONS_BAUSTELLE_SHORT_TERM_MIA_KARTE,
  ICONS_BAUSTELLE_MIA_NAVI,
  ICONS_BAUSTELLE_MIA_NAVI_ON,
} from '@/assets';
import { Popup } from 'maplibre-gl';
import { baustelleMiaService } from '../baustelle-mia/baustelle-mia.service';
import { karteService } from './karte.service';
import { Layer } from './layer';

export class BaustelleMiaLayer extends Layer {
  legendeImageUrl = ICONS_BAUSTELLE_MIA_NAVI as any;
  legendeImageUrlOn = ICONS_BAUSTELLE_MIA_NAVI_ON as any;

  legende = [
    {
      imgUrl: ICONS_BAUSTELLE_MIA_KARTE as any,
      desc: this.name,
    },
  ];

  sourcePointLayer = 'mia_point_db';
  sourceLineLayer = 'mia_line_db';

  static KEY_NAME = 'BaustelleMiaLayer';
  static KEYS = {
    ICONS: BaustelleMiaLayer.KEY_NAME + '_icons',
    MARKER: BaustelleMiaLayer.KEY_NAME + '_marker',
    MARKER_SHORT_TERM: BaustelleMiaLayer.KEY_NAME + '_marker_short_term',
    LINESTRINGS: BaustelleMiaLayer.KEY_NAME + '_linestrings',
  };
  eventFunction = {
    click: this.onFeatureClick.bind(this),
    mouseenter: this.onFeatureMouseEnter.bind(this),
    mouseleave: this.onFeatureMouseLeave.bind(this),
  };

  constructor() {
    super('Baustellen');
  }

  async add() {
    this.addEvents();
    try {
      await karteService.loadImage(
        ICONS_BAUSTELLE_MIA_KARTE as any,
        BaustelleMiaLayer.KEYS.MARKER,
      );
      await karteService.loadImage(
        ICONS_BAUSTELLE_SHORT_TERM_MIA_KARTE as any,
        BaustelleMiaLayer.KEYS.MARKER_SHORT_TERM,
      );
    } catch (e) {
      console.error(e);
    }

    this.map.addLayer(
      {
        id: BaustelleMiaLayer.KEYS.LINESTRINGS,
        type: 'line',
        source: karteService.apiSourceKey,
        'source-layer': this.sourceLineLayer,
        // filter: ['==', ['get', 'event_type'], 'Baustelle'],
        minzoom: 8,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#FF6A13',
          'line-width': 2,
          'line-offset': [
            'interpolate',
            ['exponential', 1],
            ['zoom'],
            10,
            3,
            11,
            5,
          ],
        },
      },
      this.layerAnchorName,
    );
    this.map.addLayer(
      {
        id: BaustelleMiaLayer.KEYS.ICONS,
        type: 'symbol',
        source: karteService.apiSourceKey,
        'source-layer': this.sourcePointLayer,
        // filter: ['==', ['get', 'event_type'], 'Baustelle'],
        layout: {
          // 'icon-image': BaustelleMiaLayer.KEYS.MARKER,
          "icon-image": [
            "match",
            ["get", "roadworks_duration"],
            "SHORT_TERM",
            BaustelleMiaLayer.KEYS.MARKER_SHORT_TERM,
            "LONG_TERM",
            BaustelleMiaLayer.KEYS.MARKER,
            BaustelleMiaLayer.KEYS.MARKER,            
          ],
          // 'icon-size': 0.15,
          "icon-size": ["interpolate", ["linear"], ["zoom"], 7, 0.1, 15, 0.3],
        },
      },
      this.layerAnchorName,
    );
    this.visible = true;
  }

  async addEvents() {
    this.map.on('click', BaustelleMiaLayer.KEYS.ICONS, this.eventFunction.click);
    this.map.on(
      'mouseenter',
      BaustelleMiaLayer.KEYS.ICONS,
      this.eventFunction.mouseenter,
    );
    this.map.on(
      'mouseleave',
      BaustelleMiaLayer.KEYS.ICONS,
      this.eventFunction.mouseleave,
    );
  }

  async removeEvents() {
    this.map.off('click', BaustelleMiaLayer.KEYS.ICONS, this.eventFunction.click);
    this.map.off(
      'mouseenter',
      BaustelleMiaLayer.KEYS.ICONS,
      this.eventFunction.mouseenter,
    );
    this.map.off(
      'mouseleave',
      BaustelleMiaLayer.KEYS.ICONS,
      this.eventFunction.mouseleave,
    );
  }

  async onFeatureClick(e: any) {
    const feature = e.features[0];
    const meldung = await baustelleMiaService.fetchOne(
      feature.properties.id,
    );
    if (!meldung) {
      return;
    }
    new Popup({ maxWidth: '400px' })
      .setLngLat(e.lngLat)
      .setHTML(
        `
        <h6>${meldung.id}</h6>

        <table>
          <tbody>
            ${
              meldung.startTime
                ? `<tr>
              <th>Von:</th>
              <td>
                ${meldung.startTime.toLocaleString()}
              </td>
            </tr>`
                : ''
            }
            ${
              meldung.endTime
                ? `<tr>
              <th>Bis:</th>
              <td>${meldung.endTime.toLocaleString()}</td>
            </tr>`
                : ''
            }
          </tbody>
        </table>
        <p class="mt-1">${meldung.generalPublicComment}</p>
        `,
      )
      .addTo(this.map);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFeatureMouseEnter(e: any) {
    this.map.getCanvas().style.cursor = 'pointer';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFeatureMouseLeave(e: any) {
    this.map.getCanvas().style.cursor = '';
  }

  async remove() {
    this.removeEvents();
    if (this.map.getLayer(BaustelleMiaLayer.KEYS.ICONS)) {
      this.map.removeLayer(BaustelleMiaLayer.KEYS.ICONS);
    }
    if (this.map.getLayer(BaustelleMiaLayer.KEYS.LINESTRINGS)) {
      this.map.removeLayer(BaustelleMiaLayer.KEYS.LINESTRINGS);
    }
    this.visible = false;
  }

  async update() {
    return;
  }
}
