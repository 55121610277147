import { VerwaltungsgrenzeInterface } from './verwaltungsgrenze.interface';

export class Verwaltungsgrenze {
  id: number;
  niederlassung: string;
  name: string;

  constructor(verwaltungsgrenzeInterface: VerwaltungsgrenzeInterface) {
    this.id = verwaltungsgrenzeInterface.id;
    this.niederlassung = verwaltungsgrenzeInterface.niederlassung;
    this.name = verwaltungsgrenzeInterface.name;
  }
}
