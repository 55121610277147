
import { router, VIEWER_ROUTES } from '@/router';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ViewerHomeView extends Vue {
  get isKorridoreAktiv() {
    return this.$route.path.includes(VIEWER_ROUTES.KORRIDORE)
  }

  get isInfrastrukturAktiv() {
    return this.$route.path.includes(VIEWER_ROUTES.INFRASTRUKTUR);
  }

  routeToKorridor() {
    router.push(VIEWER_ROUTES.getKorridorListenansichtRoute());
  }

  routeToInfrastruktur() {
    router.push(VIEWER_ROUTES.getInfrastrukturRoute());
  }

  routeToUebersicht() {
    router.push(VIEWER_ROUTES.getUebersichtRoute());
  }
}
