
import { TrafficEvent } from "@/services/traffic-event/traffic-event.class";
import { trafficEventService } from "@/services/traffic-event/traffic-event.service";
import { ETic3VerkehrsmeldungEvent } from "@/services/tic3-verkehrsmeldung/tic3-verkehrsmeldung-event.enum";
import { Component, Vue } from "vue-property-decorator";
import AOSTable from "../components/aos/table/AOSTable.component.vue";
import { verwaltungsgrenzeService } from "@/services/verwaltungsgrenze/verwaltungsgrenze.service";

@Component({
  components: {
    AOSTable,
  },
})
export default class ListenansichtView extends Vue {
  trafficEventService = trafficEventService;
  verwaltungsgrenzeService = verwaltungsgrenzeService;

  interval: any;

  public fields = [
  {
      key: "quelle",
      sortable: true,
      label: "Quelle",
      defaultLabel: "Alle", 
      formatter: this.defaultFormatter,
    },
    {
      key: "type",
      sortable: true,
      label: "Typ",
      defaultLabel: "Alle", 
      formatter: this.typeFormatter,
    },
    {
      key: "verwaltungsgrenze_id",
      sortable: true,
      label: "Verwaltungsgrenze",
      defaultLabel: "Alle", 
      formatter: this.verwaltungsgrenzeFormatter,
    },
    {
      key: "start_time",
      sortable: true,
      label: "Von",
      defaultLabel: "Von",
      formatter: this.formatDateTime,
    },
    {
      key: "end_time",
      sortable: true,
      label: "Bis",
      defaultLabel: "Bis",
      formatter: this.formatDateTime,
    },
    {
      key: "general_public_comment",
      sortable: true,
      label: "Beschreibung",
      defaultLabel: "Beschreibung",
      formatter: this.defaultFormatter,
    },
    {
      key: "lage",
      sortable: true,
      label: "Lage",
      defaultLabel: "Lage",
      formatter: this.defaultFormatter,
    },
    {
      key: "id",
      sortable: true,
      label: "ID",
      defaultLabel: "ID",
      formatter: this.defaultFormatter,
    },
  ];
  public filters: {
    [key: string]: {
      type: "text" | "date" | "options" | "number";
      options?: { value: any; text: string }[];
      value: "";
      filter: (
        item: TrafficEvent,
        value: string | number | Date
      ) => boolean;
    };
  } = {
    id: {
      type: "text",
      value: "",
      filter: (item, value) =>
        item.id ? item.id.toLowerCase().includes(value as string) : false,
    },
    type: {
      type: "options",
      options: [{value: 'de.e21x.data.traffic_event.Roadwork$Bean', text: 'Baustelle'},{value: 'de.e21x.data.traffic_event.AbnormalTraffic$Bean', text: 'Staumeldung'}, {value: 'Vollsperrung', text: 'Vollsperrung'}],
      value: "",
      filter: (item, value) =>
        item.type ? item.type === value : false,
    },
    quelle: {
      type: "options",
      options: [{value: 'mia', text: 'mia'},{value: 'tomtom', text: 'tomtom'}, {value: 'tic3', text: 'tic3'}],
      value: "",
      filter: (item, value) =>
        item.quelle ? item.quelle === value : false,
    },
    start_time: {
      type: "date",
      value: "",
      filter: (item, value) => (item.start_time ? item.start_time >= value : false),
    },
    end_time: {
      type: "date",
      value: "",
      filter: (item, value) => (item.end_time ? item.end_time <= value : false),
    },
    general_public_comment: {
      type: "text",
      value: "",
      filter: (item, value) =>
        item.general_public_comment
          ? item.general_public_comment.toLowerCase().includes(value as string)
          : false,
    },
    lage: {
      type: "text",
      value: "",
      filter: (item, value) =>
        item.lage
          ? item.lage.toLowerCase().includes(value as string)
          : false,
    },
    // eventName: {
    //   type: "options",
    //   options: ETic3VerkehrsmeldungEvent.getOptions(),
    //   value: "",
    //   filter: (item, value) =>
    //     item.eventName ? item.eventName === value : false,
    // },
    verwaltungsgrenze_id: {
      type: "options",
      options: this.verwaltungsgrenzeService.getOptions(),
      value: "",
      filter: (item, value) =>
        item.verwaltungsgrenze_id
          ? item.verwaltungsgrenze_id.toString() === value
          : false,
    },
  };

  get trafficEvents() {
    return this.trafficEventService.getAll().filter((a) => !!a.start_time);
  }

  get items() {
    const filters = this.filters;
    const keys = Object.keys(filters);
    return this.trafficEvents.filter((item) => {
      return keys.every((key) => {
        let value: any = filters[key].value;
        if (value === "") {
          return true;
        }
        const type = filters[key].type;
        if (type === "number") {
          value = Number(value);
        } else if (type === "date") {
          value = new Date(`${value} 00:00:00.0000`).getTime();
        } else if (type === "text") {
          value = (value as string).toLowerCase();
        }
        return filters[key].filter(item, value);
      });
    });
  }

  async created() {
    this.verwaltungsgrenzeService.fetchAll().then(() => {
      this.filters.verwaltungsgrenze_id.options =
        this.verwaltungsgrenzeService.getOptions();
    });
    this.trafficEventService.fetchAll();
    this.interval = setInterval(() => {
      this.trafficEventService.fetchAll();
    }, 60 * 1000);
  }

  beforeDestroy() {
    clearInterval(this.interval);
  }

  public defaultFormatter(value: any) {
    if (!value) {
      return "Keine Information";
    }
    return value;
  }

  public formatDateTime(date: Date) {
    if (!date) {
      return "Keine Information";
    }
    return date.toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  public typeFormatter(value: any) {
    if (!value && value !== 0) {
      return "Keine Information";
    }

    if( value === 'de.e21x.data.traffic_event.Roadwork$Bean' ){
      return 'Baustelle';
    } else if (value === 'de.e21x.data.traffic_event.AbnormalTraffic$Bean'){
      return 'Staumeldung';
    } else if (value === 'Vollsperrung'){
      return 'Vollsperrung';
    } else {
      return '';
    }

  }

  public verwaltungsgrenzeFormatter(value: number) {
    if (!value && value !== 0) {
      return "Keine Information";
    }
    const verwaltungsgrenze = this.verwaltungsgrenzeService.findById(value);
    if (!verwaltungsgrenze) {
      return `Keine Information (${value})`;
    }
    return verwaltungsgrenze.name;
  }
}
