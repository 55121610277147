import { apiService } from "../api/api.service";
import { notificationService } from "../notification/notification.service";
import { TrafficEvent } from "./traffic-event.class";
import { TrafficEventInterface } from "./traffic-event.interface";

class TrafficEventService {
  private trafficEvents: TrafficEvent[] = [];
  private trafficEventBaustellen: TrafficEvent[] = [];
  private tic3Staus: TrafficEvent[] = [];
  private tic3Vollsperrungen: TrafficEvent[] = [];

  private schnittstelle = "extern/traffic-event";

  async fetchAll() {
    try {
      this.trafficEvents = (
        (await apiService.get(this.schnittstelle)) as TrafficEventInterface[]
      ).map((r) => new TrafficEvent(r));
    } catch (e) {
      notificationService.sendErrorNotification(
        "Fehler beim Abfragen der TrafficEvents"
      );
    }
  }

  getAll() {
    return this.trafficEvents;
  }

  async fetchAllBaustellen() {
    // console.log('tic3-verkehrsmeldung.service: fetchAllBaustellen()');
    // MW 2021-08-13: auskommentiert, da das das update verhindert
    // if (this.tic3Baustellen.length !== 0) {
    //   return;
    // }
    try {
      // console.log(
      //   'fetch-url: ',
      //   this.schnittstelle + '/baustelle' + '?' + new Date().getTime(),
      // );
      // cache-buster
      this.trafficEventBaustellen = (
        (await apiService.get(
          this.schnittstelle + "/baustelle" + "?" + new Date().getTime()
        )) as TrafficEventInterface[]
      ).map((r) => new TrafficEvent(r));
    } catch (e) {
      notificationService.sendErrorNotification(
        "Fehler beim Abfragen der TrafficEvent Baustellen"
      );
    }
  }

  getBaustellen() {
    return this.trafficEventBaustellen;
  }

  // getBaustellenFeatures() {
  //   return this.getBaustellen().reduce((features, stau) => {
  //     features.push(...stau.getFeatures());
  //     return features;
  //   }, [] as any[]);
  // }

  //   findBaustelleById(id: string) {
  //     return this.getBaustellen().find((stau) => stau.id === id);
  //   }

  //   async fetchAllStaus() {
  //     if (this.tic3Staus.length !== 0) {
  //       return;
  //     }
  //     try {
  //       this.tic3Staus = ((await apiService.get(
  //         this.schnittstelle + '/stau',
  //       )) as Tic3VerkehrsmeldungInterface[]).map(
  //         (r) => new Tic3Verkehrsmeldung(r),
  //       );
  //     } catch (e) {
  //       notificationService.sendErrorNotification(
  //         'Fehler beim Abfragen der Tic3Staus',
  //       );
  //     }
  //   }

  //   getStaus() {
  //     return this.tic3Staus;
  //   }

  //   getStausFeatures() {
  //     return this.getStaus().reduce((features, stau) => {
  //       features.push(...stau.getFeatures());
  //       return features;
  //     }, [] as any[]);
  //   }

  //   findStauById(id: string) {
  //     return this.getStaus().find((stau) => stau.id === id);
  //   }

  //   async fetchAllVollsperrungen() {
  //     if (this.tic3Vollsperrungen.length !== 0) {
  //       return;
  //     }
  //     try {
  //       this.tic3Vollsperrungen = ((await apiService.get(
  //         this.schnittstelle + '/vollsperrung',
  //       )) as Tic3VerkehrsmeldungInterface[]).map(
  //         (r) => new Tic3Verkehrsmeldung(r),
  //       );
  //     } catch (e) {
  //       notificationService.sendErrorNotification(
  //         'Fehler beim Abfragen der Tic3Vollsperrung',
  //       );
  //     }
  //   }

  //   getVollsperrungen() {
  //     return this.tic3Vollsperrungen;
  //   }

  //   getVollsperrungenFeatures() {
  //     return this.getVollsperrungen().reduce((features, stau) => {
  //       features.push(...stau.getFeatures());
  //       return features;
  //     }, [] as any[]);
  //   }

  //   findVollsperrungById(id: string) {
  //     return this.getVollsperrungen().find((stau) => stau.id === id);
  //   }

  //   async fetchOne(id: number) {
  //     try {
  //       return new Tic3Verkehrsmeldung(
  //         (await apiService.get(
  //           this.schnittstelle + '/' + encodeURIComponent(id),
  //         )) as Tic3VerkehrsmeldungInterface,
  //       );
  //     } catch (e) {
  //       notificationService.sendErrorNotification(
  //         `Fehler beim Abfragen der Tic3Meldung (${id})`,
  //       );
  //     }
  //   }
}

export const trafficEventService = new TrafficEventService();
