import { Point } from '@turf/turf';
import { KameraInterface } from './kamera.interface';

export class Kamera {
  id: string;
  imageUrl: string;
  videoUrl: string;
  nameLong: string;
  nameShort: string;
  viewDirection: string;
  bundesland: string;
  punkt: Point;

  constructor(kameraInterface: KameraInterface) {
    this.id = kameraInterface.id;
    this.imageUrl = kameraInterface.imageUrl;
    this.videoUrl = kameraInterface.videoUrl;
    this.nameLong = kameraInterface.nameLong;
    this.nameShort = kameraInterface.nameShort;
    this.viewDirection = kameraInterface.viewDirection;
    this.bundesland = kameraInterface.bundesland;
    this.punkt = kameraInterface.punkt;
  }

  getFeature() {
    return {
      type: 'Feature',
      geometry: this.punkt,
      properties: {
        id: this.id,
      },
    };
  }
}
