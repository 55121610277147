import { AbstractControl } from './form';

export interface ValidationErrors {
  [key: string]: any;
}

export interface Validator {
  validate(control: AbstractControl): ValidationErrors | null;
  registerOnValidatorChange?(fn: () => void): void;
}

export type ValidatorFn = (control: AbstractControl) => ValidationErrors | null;

export enum FormBuilderStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED',
}

export interface AbstractControlOptions {
  validators?: ValidatorFn | ValidatorFn[] | null;
  updateOn?: 'change' | 'blur' | 'submit';
}
