import { featureCollection } from '@turf/turf';
import { apiService } from '../api/api.service';
import { notificationService } from '../notification/notification.service';
import { Nba } from './nba.class';
import { NbaInterface } from './nba.interface';

class NbaService {
  private nbas: Nba[] = [];

  private schnittstelle = 'extern/nba';

  getNbas() {
    return this.nbas;
  }

  getFeatureCollection() {
    return featureCollection(this.nbas.map((b) => b.getFeature()) as any);
  }

  findById(id: string) {
    return this.nbas.find((b) => b.id === id);
  }

  async fetchNbas() {
    try {
      this.nbas = ((await apiService.get(
        this.schnittstelle,
      )) as NbaInterface[]).map((b) => new Nba(b));
    } catch (e) {
      notificationService.sendErrorNotification(
        'Fehler beim Abfragen der Nbas',
      );
    }
  }

  async fetchOne(id: number) {
    try {
      return new Nba(
        (await apiService.get(
          this.schnittstelle + '/' + encodeURIComponent(id),
        )) as NbaInterface,
      );
    } catch (e) {
      notificationService.sendErrorNotification(
        `Fehler beim Abfragen der Nba (${id})`,
      );
    }
  }
}

export const nbaService = new NbaService();
