import { TomtomStauobjektInterface } from './tomtom-stauobjekt.interface';

export class TomtomStauobjekt {
  public id: string;
  public creationTime?: Date;
  public delayTime?: number;
  public startTime?: Date;
  public endTime?: Date;
  public eventcodes: string;
  public generalPublicComment: string;
  public lage: string;
  public abnormalTrafficType: string;
  public trafficTrendType: string;
  public averageSpeed: number;
  public fromNode: string;
  public toNode: string;
  public openlr?: string;
  public startpunkt: [number, number];
  public endpunkt: [number, number];
  public linestring: Array<Array<[number, number]>>;


  constructor(tomtomStauobjekt: TomtomStauobjektInterface) {
    this.id = tomtomStauobjekt.id;

    if(tomtomStauobjekt.creationTime) {
      this.creationTime = new Date(tomtomStauobjekt.creationTime);
    }
    if(tomtomStauobjekt.delayTime) {
      this.delayTime = tomtomStauobjekt.delayTime;
    }
    if(tomtomStauobjekt.startTime) {
      this.startTime = new Date(tomtomStauobjekt.startTime);
    }
    if(tomtomStauobjekt.endTime) {
      this.endTime = new Date(tomtomStauobjekt.endTime);
    }

    this.eventcodes = tomtomStauobjekt.eventcodes;
    this.generalPublicComment = tomtomStauobjekt.generalPublicComment;
    this.lage = tomtomStauobjekt.lage;
    this.abnormalTrafficType = tomtomStauobjekt.abnormalTrafficType;
    this.trafficTrendType = tomtomStauobjekt.trafficTrendType;
    this.averageSpeed = tomtomStauobjekt.averageSpeed;
    this.fromNode = tomtomStauobjekt.fromNode;
    this.toNode = tomtomStauobjekt.toNode;
    if(tomtomStauobjekt.openlr){
      this.openlr = tomtomStauobjekt.openlr;
    }
    this.startpunkt = tomtomStauobjekt.startpunkt;
    this.endpunkt = tomtomStauobjekt.endpunkt;
    this.linestring = tomtomStauobjekt.linestring;

  }

  getFeatures() {
    const features: any[] = [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: this.startpunkt,
        },
        properties: {
          id: this.id,
        },
      },
    ];
    if (this.linestring) {
      features.push({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: this.linestring,
        },
        properties: {
          id: this.id,
        },
      });
    }
    return features;
  }
}
