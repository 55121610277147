import { configService } from '@/config/config.service';
import { isProduction } from '@/helper/isProduction';
import { keycloakService } from '@/keycloak/keycloak.service';

class ApiService {

  // public url = isProduction()
  //   ? location.origin + '/api/'
  //   : 'http://localhost:3001/';

  public url = configService.getApiUrl();
  

  public async get(schnittstelle: string) {
    // const finalUrl = this.url + schnittstelle;
    const finalUrl = configService.getApiUrl() + schnittstelle;
    const response = await fetch(finalUrl, {
      headers: {
        Authorization: `Bearer ${keycloakService.getToken()}`,
      }
    });
    if (!response.ok) {
      throw new Error(
        `Fehler bei der GET Anfrage an API (${finalUrl}) (${response.statusText})`,
      );
    }
    return response.json();
  }

  public async post(schnittstelle: string, body: { [key: string]: any }) {
    // const finalUrl = this.url + schnittstelle;
    const finalUrl = configService.getApiUrl() + schnittstelle;
    const response = await fetch(finalUrl, {
      headers: {
        // tslint:disable-next-line: object-literal-key-quotes
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${keycloakService.getToken()}`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error(
        `Fehler bei der Post Anfrage an API (${finalUrl}) (${response.statusText})`,
      );
    }
    return response.json();
  }

  public async put(
    schnittstelle: string,
    id: number,
    body: { [key: string]: any },
  ) {
    const finalUrl = `${this.url}${schnittstelle}/${id}`;
    const response = await fetch(finalUrl, {
      headers: {
        // tslint:disable-next-line: object-literal-key-quotes
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${keycloakService.getToken()}`,
      },
      method: 'PUT',
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error(
        `Fehler bei der Post Anfrage an API (${finalUrl}) (${response.statusText})`,
      );
    }
    return response.json();
  }

  public async delete(schnittstelle: string, id: number | string) {
    const finalUrl = `${this.url}${schnittstelle}/${id}`;
    const response = await fetch(finalUrl, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${keycloakService.getToken()}`,
      }
    });
    if (!response.ok) {
      throw new Error(
        `Fehler bei der Delete Anfrage an API (${finalUrl}) (${response.statusText})`,
      );
    }
    return response.json();
  }

  getPdfUrl(pdfName: string) {
    return this.url + 'resources/pdf/' + pdfName;
  }
}

export const apiService = new ApiService();
