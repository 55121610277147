import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

// VIEWER
import ViewerHomeView from '@/views/viewer/ViewerHome.view.vue';
import KarteViewerView from '@/views/viewer/KarteViewer.view.vue';
import ReleasenotesView from '@/views/Releasenotes.view.vue';
import ImpressumView from '@/views/Impressum.view.vue';
import TestdatenView from '@/views/Testdaten.view.vue';
import ListenansichtView from '@/views/Listenansicht.view.vue';
import HilfeView from '@/views/Hilfe.view.vue';

import KorridoreViewerView from '@/views/viewer/korridore/KorridoreViewer.view.vue';
import KorridoreViewerHomeView from '@/views/viewer/korridore/KorridoreViewerHome.view.vue';

import InfrastrukturViewerView from '@/views/viewer/infrastruktur/InfrastrukturViewer.view.vue';
import InfrastrukturViewerHomeView from '@/views/viewer/infrastruktur/InfrastrukturViewerHome.view.vue';

import UebersichtViewerView from '@/views/viewer/uebersicht/UebersichtViewer.view.vue';
import UebersichtViewerHomeView from '@/views/viewer/uebersicht/UebersichtViewerHome.view.vue';

import NichtAuthorisiertView from '@/views/NichtAuthorisiert.vue';
import { keycloakService } from '@/keycloak/keycloak.service';

import { configService } from '../config/config.service';

Vue.use(VueRouter);

export const BASE_ROUTES = {
  EDITOR: '/editor',
  VIEWER: '/viewer',
  // RELEASENOTES: '/releasenotes',
  IMPRESSUM: '/impressum',
  TESTDATEN: '/testdaten',
  WFMS: '/wfms',
  LISTENANSICHT: '/listenansicht',
  HILFE: '/dokumentation',
  NICHT_AUTHORISIERT: '/nicht_authorisiert',
};

export const VIEWER_ROUTES = {
  KORRIDORE: '/korridore',
  INFRASTRUKTUR: '/infrastruktur',
  UEBERSICHT: '/uebersicht',
  KARTE: '/karte',

  getKorridorListenansichtRoute: () => `${BASE_ROUTES.VIEWER}${VIEWER_ROUTES.KORRIDORE}`,
  getInfrastrukturRoute: () => `${BASE_ROUTES.VIEWER}${VIEWER_ROUTES.INFRASTRUKTUR}`,
  getUebersichtRoute: () => `${BASE_ROUTES.VIEWER}${VIEWER_ROUTES.UEBERSICHT}`,
  getKarteRoute: () => `${BASE_ROUTES.VIEWER}${VIEWER_ROUTES.KARTE}`,
};

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: BASE_ROUTES.VIEWER,
  },
  {
    path: BASE_ROUTES.VIEWER,
    component: ViewerHomeView,
    name: 'Verkehrslage',
    redirect: BASE_ROUTES.VIEWER + VIEWER_ROUTES.KORRIDORE,
    children: [
      {
        path: BASE_ROUTES.VIEWER + VIEWER_ROUTES.KORRIDORE,
        component: KorridoreViewerHomeView,
        name: 'KorridorViewerHome',
        children: [
          {
            path: '',
            component: KorridoreViewerView,
            name: 'Verkehrslage Deutschland',
          },
        ],
        beforeEnter: (to, from, next) => {
          if (keycloakService.canKorridorLesen()) {
            next();
          } else {
            next(VIEWER_ROUTES.getKarteRoute());
          }
        },
      },
      {
        path: BASE_ROUTES.VIEWER + VIEWER_ROUTES.INFRASTRUKTUR,
        component: InfrastrukturViewerHomeView,
        name: 'InfrastrukturViewer',
        children: [
          {
            path: '',
            component: InfrastrukturViewerView,
          },
        ],
      },
      {
        path: BASE_ROUTES.VIEWER + VIEWER_ROUTES.UEBERSICHT,
        component: UebersichtViewerHomeView,
        name: 'UebersichtViewer',
        children: [
          {
            path: '',
            component: UebersichtViewerView,
          },
        ],
      },
      {
        path: BASE_ROUTES.VIEWER + VIEWER_ROUTES.KARTE,
        component: KarteViewerView,
        name: 'KarteViewer',
        beforeEnter: (to, from, next) => {
          if (keycloakService.canKorridorLesen()) {
            next(VIEWER_ROUTES.getKorridorListenansichtRoute());
          } else {
            next();
          }
        },
      },
    ],
  },
  // {
  //   path: BASE_ROUTES.RELEASENOTES,
  //   component: ReleasenotesView,
  //   name: 'Versionshinweise',
  // },
  {
    path: BASE_ROUTES.IMPRESSUM,
    component: ImpressumView,
    name: 'Impressum',
  },
  {
    path: BASE_ROUTES.TESTDATEN,
    component: TestdatenView,
    name: 'Testdaten',
  },
  {
    path: BASE_ROUTES.WFMS,
    beforeEnter: (to, from, next) => {
      window.open(configService.getWfmsLink(), 'wfms');
    },
    name: 'WFMS',
  },
  {
    path: BASE_ROUTES.LISTENANSICHT,
    component: ListenansichtView,
    name: 'Meldungsübersicht',
  },
  {
    path: BASE_ROUTES.HILFE,
    component: HilfeView,
    name: 'Hilfe',
  },
  {
    path: BASE_ROUTES.NICHT_AUTHORISIERT,
    component: NichtAuthorisiertView,
    name: 'Nicht authorisiert',
  },
];

export const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  //  change document.title if not WFMS
  if (to.name != 'WFMS') {
    document.title = `SKM - ${to.name}`;
  }
  if (!keycloakService.canKarteLesen() && to.fullPath !== BASE_ROUTES.NICHT_AUTHORISIERT) {
    next(BASE_ROUTES.NICHT_AUTHORISIERT);
  } else if (keycloakService.canKarteLesen() && to.fullPath === BASE_ROUTES.NICHT_AUTHORISIERT) {
    next(BASE_ROUTES.VIEWER);
  } else {
    next();
  }
});
