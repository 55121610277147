import {
  ICONS_KAMERA_KARTE,
  ICONS_KAMERA_OFF,
  ICONS_KAMERA_ON,
} from '@/assets';
import { Popup } from 'maplibre-gl';
import { kameraService } from '../kamera/kamera.service';
import { karteService } from './karte.service';
import { Layer } from './layer';

export class KameraLayer extends Layer {
  legendeImageUrl = ICONS_KAMERA_OFF as any;
  legendeImageUrlOn = ICONS_KAMERA_ON as any;

  legende = [
    {
      imgUrl: ICONS_KAMERA_KARTE as any,
      desc: this.name,
    },
  ];

  sourceLayer = 'kamera_db';

  static KEY_NAME = 'KameraLayer';
  static KEYS = {
    ICONS: KameraLayer.KEY_NAME + '_icons',
    MARKER: KameraLayer.KEY_NAME + '_marker',
  };
  eventFunction = {
    click: this.onFeatureClick.bind(this),
    mouseenter: this.onFeatureMouseEnter.bind(this),
    mouseleave: this.onFeatureMouseLeave.bind(this),
  };

  constructor() {
    super('Kamera');
  }

  async add() {
    this.addEvents();
    try {
      await karteService.loadImage(
        ICONS_KAMERA_KARTE as any,
        KameraLayer.KEYS.MARKER,
      );
    } catch (e) {
      console.error(e);
    }
    this.map.addLayer(
      {
        id: KameraLayer.KEYS.ICONS,
        type: 'symbol',
        source: karteService.apiSourceKey,
        'source-layer': this.sourceLayer,
        layout: {
          'icon-image': KameraLayer.KEYS.MARKER,
          'icon-size': 0.15,
        },
      },
      this.layerAnchorName,
    );
    this.visible = true;
  }

  async addEvents() {
    this.map.on('click', KameraLayer.KEYS.ICONS, this.eventFunction.click);
    this.map.on(
      'mouseenter',
      KameraLayer.KEYS.ICONS,
      this.eventFunction.mouseenter,
    );
    this.map.on(
      'mouseleave',
      KameraLayer.KEYS.ICONS,
      this.eventFunction.mouseleave,
    );
  }

  async removeEvents() {
    this.map.off('click', KameraLayer.KEYS.ICONS, this.eventFunction.click);
    this.map.off(
      'mouseenter',
      KameraLayer.KEYS.ICONS,
      this.eventFunction.mouseenter,
    );
    this.map.off(
      'mouseleave',
      KameraLayer.KEYS.ICONS,
      this.eventFunction.mouseleave,
    );
  }

  async onFeatureClick(e: any) {
    const feature = e.features[0];
    const kamera = await kameraService.fetchOne(feature.properties.id);
    if (!kamera) {
      return;
    }
    if (kamera.bundesland != 'HE') {
      new Popup({ maxWidth: '360px' })
        .setLngLat(e.lngLat)
        .setHTML(
          `
          <span><b>${kamera.nameLong}</b></span>
          <span><b>${kamera.viewDirection}</b></span>
          <img class="w-100" src="${kamera.imageUrl}${
            kamera.imageUrl.includes('?') ? '' : '?'
          }&q=${new Date().getTime()}">
        `,
        )
        .addTo(this.map);
    } else {
      // Hessen
      // Statische Webcam-Bilder sind alt (Juni 2021)
      // daher Einbindung der Video-Daten
      new Popup({ maxWidth: '360px' })
        .setLngLat(e.lngLat)
        .setHTML(
          `
          <span><b>${kamera.nameLong}</b></span>
          <video preload="none" controls="" class="w-100" >
          <source src="${kamera.videoUrl}${
            kamera.videoUrl.includes('?') ? '' : '?'
          }&q=${new Date().getTime()}" type="video/mp4"> Your browser does not support the video tag.</video>
        `,
        )
        .addTo(this.map);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFeatureMouseEnter(e: any) {
    this.map.getCanvas().style.cursor = 'pointer';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFeatureMouseLeave(e: any) {
    this.map.getCanvas().style.cursor = '';
  }

  async remove() {
    this.removeEvents();
    if (this.map.getLayer(KameraLayer.KEYS.ICONS)) {
      this.map.removeLayer(KameraLayer.KEYS.ICONS);
    }
    this.visible = false;
  }

  async update() {
    return;
  }
}
