import { Point } from '@turf/turf';
import { DwistaInterface } from './dwista.interface';
import { dwistaService } from './dwista.service';

export class Dwista {
  id: string;
  strasse: string;
  url: string;
  standort: string;
  fahrtrichtung: string;
  punkt: Point;

  constructor(dwistaInterface: DwistaInterface) {
    this.id = dwistaInterface.id;
    this.strasse = dwistaInterface.strasse;
    this.url = dwistaInterface.url;
    this.standort = dwistaInterface.standort;
    this.fahrtrichtung = dwistaInterface.fahrtrichtung;
    this.punkt = dwistaInterface.punkt;
  }

  getFeature() {
    return {
      type: 'Feature',
      geometry: this.punkt,
      properties: {
        id: this.id,
      },
    };
  }

  getUrl() {
    return dwistaService.getImageUrl(this);
  }
}
