import { Map, NavigationControl } from "maplibre-gl";
import { NbaLayer } from "./nba-layer";
import { KameraLayer } from "./kamera-layer";
import { Layer } from "./layer";
import { RouteLayer } from "./route-layer";
import { VrzLayer } from "./vrz-layer";
import { StartzielLayer } from "./startziel-layer";
import { StauLayer } from "./stau-layer";
import { BaustelleLayer } from "./baustelle-layer";
import { BaustelleMiaLayer } from "./baustelle-mia-layer";
import { VollsperrungLayer } from "./vollsperrung-layer";
import { VerkehrslageVectorLayer } from "./verkehrslage-vector-layer";
import { VerkehrslageHighContrastVectorLayer } from "./verkehrslage-highcontrast-vector-layer";
import { keycloakService } from "@/keycloak/keycloak.service";
import { apiService } from "../api/api.service";
import { configService } from "@/config/config.service";
import { karteParameterService } from "../karte-parameter/karte-parameter.service";

class KarteService {
  public apiSourceKey = "api_source";

  private map!: Map;
  private loadedImages: string[] = [];
  layer: Layer[] = [];
  private emptySourceKey = "empty";
  private layerHierarchyDict: { [layerName: string]: string } = {};
  private currentZIndex = 0;
  private timerid : any;
  
  getMap() {
    if (!this.map) {
      throw new Error("init() vorher aufrufen");
    }
    return this.map;
  }

  destroy() {
    this.layer.forEach((l) => l.visible && l.remove());
    this.map.remove();
    (this.map as any) = undefined;
    this.loadedImages = [];
    this.layer = [];
    clearInterval(this.timerid);
  }

  init() {
    return new Promise((resolve) => {
      const maxZoom = 16;
      const minZoom = 5;
      // const center: [number, number] = [10.273, 52.092];
      // const zoom = 5.75;

      this.map = new Map({
        // center: center,
        center: karteParameterService.getCenter(),
        container: "map",
        minZoom: minZoom,
        maxZoom: maxZoom,
        // zoom: zoom,
        zoom: karteParameterService.getZoom(),
        style: configService.getMapUrl() + "/styles/knm-darkmode/style.json",
        hash: true,
        attributionControl: false,
        transformRequest: (url, resourceType) => {
          if (resourceType === "Tile" && url.startsWith(configService.getApiUrl())) {
            return {
              url,
              headers: {
                Authorization: `Bearer ${keycloakService.getToken()}`,
              },
            };
          }
          return {
            url,
          };
        },
      });

      // Navigation
      const nav = new NavigationControl({ visualizePitch: true });
      this.map.addControl(nav, "bottom-right");

      // Erst nach load ist die Karte bereit
      this.map.on("load", () => {
        this.map.addSource(this.emptySourceKey, {
          type: "geojson",
          data: { type: "FeatureCollection", features: [] },
        });
        this.map.addSource(this.apiSourceKey, {
          type: "vector",
          tiles: [configService.getApiUrl() + 'tile/{x}/{y}/{z}.mvt'],
        });
        const startZielLayer = new StartzielLayer();
        startZielLayer.add();
        this.layer = [
          ...(keycloakService.canKorridorLesen() ? [new RouteLayer()] : []),
          startZielLayer,
          new VerkehrslageVectorLayer(),
          new VerkehrslageHighContrastVectorLayer(),
          // new BaustelleLayer(),
          new BaustelleMiaLayer(),
          new StauLayer(),
          new NbaLayer(),
          new KameraLayer(),
          new VollsperrungLayer(),
          new VrzLayer(),
          // new LclPointLayer(),
        ];
        this.timerid = setInterval(() => this.updateApiSource(), 90 * 1000);
        
        // Aktiviere Layer aus karteParameterService
        karteParameterService.getActiveLayers().forEach(item => {
          this.layer.forEach(
            value => {
              if ( item === value.name){
                // console.log("Reaktiviere Layer", item);
                value.toggle();
              }
            }
          )  
        });

        resolve(true);
      });

      this.map.on("boxzoomend", (e) => {
        // console.log('boxzoomend (z,x,y):', this.map.getZoom(), this.map.getCenter().lng, this.map.getCenter().lat);
        karteParameterService.setCenter(
          this.map.getCenter().lng,
          this.map.getCenter().lat
        );
        karteParameterService.setZoom(this.map.getZoom());
      });

      this.map.on("moveend", (e) => {
        // console.log('moveend (z,x,y):', this.map.getZoom(), this.map.getCenter().lng, this.map.getCenter().lat);
        karteParameterService.setCenter(
          this.map.getCenter().lng,
          this.map.getCenter().lat
        );
        karteParameterService.setZoom(this.map.getZoom());
      });
    });
  }

  loadImage(url: string, key: string, addImageOptions = {}) {
    return new Promise((resolve, reject) => {
      if (this.loadedImages.includes(key)) {
        resolve(true);
        return;
      }
      this.map.loadImage(url, (error: any, image: any) => {
        if (error) {
          reject(error);
          return;
        }
        this.map.addImage(key, image, addImageOptions);
        this.loadedImages.push(key);
        resolve(true);
      });
    });
  }

  getLayer<T>(arg: Function) {
    return this.layer.find((l) => l instanceof arg) as T | undefined;
  }

  createLayerAnchor(layerName: any, beforeLayer?: string) {
    this.currentZIndex = this.currentZIndex + 1;
    const anchorLayerName = `${layerName}_${this.currentZIndex}`;
    this.map.addLayer(
      {
        id: anchorLayerName,
        type: "symbol",
        source: this.emptySourceKey,
      },
      beforeLayer
    );
    this.layerHierarchyDict[layerName] = anchorLayerName;
    return anchorLayerName;
  }

  updateApiSource() {
    if (this.map) {
      (this.map.getSource(this.apiSourceKey) as any).setTiles([
        configService.getApiUrl() + 'tile/{x}/{y}/{z}.mvt',
      ]);
    } else {
      console.log("this.map is undefined");
    }
  }
}

export const karteService = new KarteService();
