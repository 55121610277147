import { Validator, ValidatorFn, AbstractControlOptions } from './types';
import { normalizeValidator } from './normalizeValidator';
import { FormBuilderValidators } from './form-builder-validators';
import { AbstractControl, FormGroup, FormArray } from './model';

export function composeValidators(
  validators: Array<Validator | ValidatorFn>,
): ValidatorFn | null {
  return validators != null
    ? FormBuilderValidators.compose(validators.map(normalizeValidator))
    : null;
}

export function _findInFormBuilder(
  control: AbstractControl,
  path: Array<string | number> | string,
  delimiter: string,
) {
  if (path == null) {
    return null;
  }

  if (!Array.isArray(path)) {
    path = path.split(delimiter);
  }
  if (Array.isArray(path) && path.length === 0) {
    return null;
  }

  return path.reduce((v: AbstractControl | null, name) => {
    if (v instanceof FormGroup) {
      // eslint-disable-next-line
      return v.controls.hasOwnProperty(name as string)
        ? v.controls[name]
        : null;
    }

    if (v instanceof FormArray) {
      return v.at(name as number) || null;
    }

    return null;
  }, control);
}

export function isOptionsObj(
  validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
): boolean {
  return (
    validatorOrOpts != null &&
    !Array.isArray(validatorOrOpts) &&
    typeof validatorOrOpts === 'object'
  );
}

export function coerceToValidator(
  validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
): ValidatorFn | null {
  const validator = (isOptionsObj(validatorOrOpts)
    ? (validatorOrOpts as AbstractControlOptions).validators
    : validatorOrOpts) as ValidatorFn | ValidatorFn[] | null;

  return Array.isArray(validator)
    ? composeValidators(validator)
    : validator || null;
}
