import { featureCollection } from '@turf/turf';
import { apiService } from '../api/api.service';
import { notificationService } from '../notification/notification.service';
import { Kamera } from './kamera.class';
import { KameraInterface } from './kamera.interface';

class KameraService {
  private kameras: Kamera[] = [];

  private schnittstelle = 'extern/kamera';

  getKameras() {
    return this.kameras;
  }

  getFeatureCollection() {
    return featureCollection(this.kameras.map((b) => b.getFeature()) as any);
  }

  findById(id: string) {
    return this.kameras.find((b) => b.id === id);
  }

  async fetchKameras() {
    try {
      this.kameras = ((await apiService.get(
        this.schnittstelle,
      )) as KameraInterface[]).map((b) => new Kamera(b));
    } catch (e) {
      notificationService.sendErrorNotification(
        'Fehler beim Abfragen der Kameras',
      );
    }
  }

  async fetchOne(id: string) {
    try {
      return new Kamera(
        (await apiService.get(
          this.schnittstelle + '/' + encodeURIComponent(id),
        )) as KameraInterface,
      );
    } catch (e) {
      notificationService.sendErrorNotification(
        `Fehler beim Abfragen der Kamera (${id})`,
      );
    }
  }
}

export const kameraService = new KameraService();
