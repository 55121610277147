
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Route } from '@/services/route/route.class';

@Component({})
export default class ReisezeitComponent extends Vue {
  @Prop() route!: Route;

  grundReisezeit() {
    return (this.route.grundReisezeit / 60).toFixed(0);
  }
  aktuelleReisezeit() {
    return (this.route.aktuelleReisezeit / 60).toFixed(0);
  }
  verlustzeit() {
    return (this.route.verlustzeit / 60).toFixed(0);
  }

  reisezeitstring(){
    if(this.route.verlustzeit < 0){
      return (this.route.grundReisezeit / 60).toFixed(0) + " -" + Math.abs(this.route.verlustzeit / 60).toFixed(0);
    } else {
      return (this.route.grundReisezeit / 60).toFixed(0) + " +" +Math.abs(this.route.verlustzeit / 60).toFixed(0);
    }
  }
}
