import { AbstractControl, FormControl, FormArray, FormGroup } from './model';
import { ValidatorFn, AbstractControlOptions } from './types';

function isAbstractControlOptions(
  options: AbstractControlOptions | { [key: string]: any },
): options is AbstractControlOptions {
  return options.validators !== undefined || options.updateOn !== undefined;
}

export class FormBuilder {
  group(
    controlsConfig: { [key: string]: any },
    options: AbstractControlOptions | { [key: string]: any } | null = null,
  ): FormGroup {
    const controls = this._reduceControls(controlsConfig);

    let validators: ValidatorFn | ValidatorFn[] | null = null;

    if (options != null) {
      if (isAbstractControlOptions(options)) {
        // `options` are `AbstractControlOptions`
        validators = options.validators != null ? options.validators : null;
      } else {
        // `options` are legacy form group options
        validators = options['validator'] != null ? options['validator'] : null;
      }
    }

    return new FormGroup(controls, { validators });
  }

  control(
    formState: any,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
  ): FormControl {
    return new FormControl(formState, validatorOrOpts);
  }

  array(
    controlsConfig: any[],
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
  ): FormArray {
    const controls = controlsConfig.map((c) => this._createControl(c));
    return new FormArray(controls, validatorOrOpts);
  }

  private _reduceControls(controlsConfig: {
    [k: string]: any;
  }): { [key: string]: AbstractControl } {
    const controls: { [key: string]: AbstractControl } = {};
    Object.keys(controlsConfig).forEach((controlName) => {
      controls[controlName] = this._createControl(controlsConfig[controlName]);
    });
    return controls;
  }

  private _createControl(controlConfig: any): AbstractControl {
    if (
      controlConfig instanceof FormControl ||
      controlConfig instanceof FormGroup ||
      controlConfig instanceof FormArray
    ) {
      return controlConfig;
    } else if (Array.isArray(controlConfig)) {
      const value = controlConfig[0];
      const validator: ValidatorFn =
        controlConfig.length > 1 ? controlConfig[1] : null;
      return this.control(value, validator);
    } else {
      return this.control(controlConfig);
    }
  }
}
