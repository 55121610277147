import { ImportStandInterface } from './import-stand.interface';

export class ImportStand {
  id: string;
  displayName: string;
  lastImport: Date;
  lastUpdateData: Date;

  constructor(importStandInterface: ImportStandInterface) {
    this.id = importStandInterface.id;
    this.displayName = importStandInterface.displayName;
    this.lastImport = new Date(importStandInterface.lastImport);
    this.lastUpdateData = new Date(importStandInterface.lastUpdateData);
  }
}
