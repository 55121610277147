import { apiService } from '../api/api.service';
import { BaustelleProfund } from '../baustelle/baustelle-profund.class';
import { BaustelleProfundInterface } from '../baustelle/baustelle-profund.interface';
import { Dwista } from '../dwista/dwista.class';
import { DwistaInterface } from '../dwista/dwista.interface';
import { Kamera } from '../kamera/kamera.class';
import { KameraInterface } from '../kamera/kamera.interface';
import { notificationService } from '../notification/notification.service';
import { Route } from './route.class';
import { RouteInterface } from './route.interface';
import { SaveRouteCommand } from './save-route.command';

class RouteService {
  private routen: Route[] = [];

  private schnittstelle = 'route';

  async fetchAllVonKorridor(korridorId: number) {
    try {
      this.routen = ((await apiService.get(
        this.schnittstelle + '/korridor/' + korridorId,
      )) as RouteInterface[]).map((r) => new Route(r));
    } catch (e) {
      notificationService.sendErrorNotification(
        'Fehler beim Abfragen der Routen',
      );
    }
  }

  async fetchBaustellen(route: Route) {
    try {
      if (route.baustellen.length > 0) {
        return;
      }
      route.baustellen = ((await apiService.get(
        this.schnittstelle + '/' + route.id + '/baustellen',
      )) as BaustelleProfundInterface[]).map((b) => new BaustelleProfund(b));
    } catch (e) {
      notificationService.sendErrorNotification(
        'Fehler beim Abfragen der Baustellen',
      );
    }
  }

  async fetchDwistas(route: Route) {
    try {
      if (route.dwistas.length > 0) {
        return;
      }
      route.dwistas = ((await apiService.get(
        this.schnittstelle + '/' + route.id + '/dwistas',
      )) as DwistaInterface[]).map((b) => new Dwista(b));
    } catch (e) {
      notificationService.sendErrorNotification(
        'Fehler beim Abfragen der Dwistas',
      );
    }
  }

  async fetchKameras(route: Route) {
    try {
      if (route.kameras.length > 0) {
        return;
      }
      route.kameras = ((await apiService.get(
        this.schnittstelle + '/' + route.id + '/kameras',
      )) as KameraInterface[]).map((b) => new Kamera(b));
    } catch (e) {
      notificationService.sendErrorNotification(
        'Fehler beim Abfragen der Kameras',
      );
    }
  }

  async fetchOne(id: number) {
    try {
      return new Route(
        (await apiService.get(this.schnittstelle + '/' + id)) as RouteInterface,
      );
    } catch (e) {
      notificationService.sendErrorNotification(
        'Fehler beim Abfragen der Routen',
      );
    }
  }

  async toggleIsAktiv(id: number) {
    try {
      return new Route(
        (await apiService.get(
          this.schnittstelle + '/' + id + '/toggleIsAktiv',
        )) as RouteInterface,
      );
    } catch (e) {
      notificationService.sendErrorNotification(
        'Fehler beim ändern des Aktivstatus',
      );
    }
  }

  async create(
    korridorId: number,
    saveCommand: SaveRouteCommand,
  ): Promise<Route> {
    const created = new Route(
      await apiService.post(this.schnittstelle + '/' + korridorId, saveCommand),
    );
    this.routen.push(created);
    return created;
  }

  async update(id: number, saveCommand: SaveRouteCommand): Promise<Route> {
    // const r = this.findById(id);
    // if (!r) {
    //   throw new Error('Route nicht gefunden (Id)');
    // }
    // const index = this.findIndex(r);
    // if (index === -1) {
    //   throw new Error('Route nicht gefunden (Index)');
    // }
    return new Route(await apiService.put(this.schnittstelle, id, saveCommand));
    // this.routen.splice(index, 1, updated);
    // return updated;
  }

  async delete(id: number) {
    const route = this.findById(id);
    if (!route) {
      throw new Error('Route nicht gefunden (Id)');
    }
    const index = this.findIndex(route);
    if (index === -1) {
      throw new Error('Route nicht gefunden(Index)');
    }
    await apiService.delete(this.schnittstelle, id);
    this.routen.splice(index, 1);
  }

  getRouten() {
    return this.routen;
  }

  findIndex(route: Route) {
    return this.routen.findIndex((r) => route === r);
  }

  findById(id: number) {
    return this.routen.find((r) => r.id === id);
  }
}

export const routeService = new RouteService();
