
import { ICONS_ALTERNATIVROUTE, ICONS_HAUPTROUTE } from '@/assets';
import { Richtung } from '@/services/richtung/richtung.class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { richtungService } from '@/services/richtung/richtung.service';
import { Route } from '@/services/route/route.class';
import { karteService } from '@/services/karte/karte.service';
import { RouteLayer } from '@/services/karte/route-layer';
import { keycloakService } from '@/keycloak/keycloak.service';
import ReisezeitComponent from '../helper/Reisezeit.component.vue';
import AktuelleReisezeitComponent from '../helper/AktuelleReisezeit.component.vue';

@Component({
  components: {
    ReisezeitComponent,
    AktuelleReisezeitComponent
  }
})
export default class RichtungViewerComponent extends Vue {
  @Prop() richtung!: Richtung;

  keycloakService = keycloakService;

  ICONS_HAUPTROUTE = ICONS_HAUPTROUTE;
  ICONS_ALTERNATIVROUTE = ICONS_ALTERNATIVROUTE;

  async setAktiv(route: Route) {
    if (!keycloakService.canKorridorSchalten()) {
      return;
    }
    await richtungService.setRouteAktiv(this.richtung.id, route.id);
    karteService.updateApiSource();
  }

  get routeLayer() {
    return karteService.getLayer<RouteLayer>(RouteLayer);
  }

  getHighlightedRichtungen() {
    return this.routeLayer ? this.routeLayer.highlightedRichtungen : [];
  }

  onRichtungClick() {
    if (!this.routeLayer) {
      return;
    }
    this.routeLayer.toggleRichtungHighlight(this.richtung);
    this.$emit('highlight');
  }
}
