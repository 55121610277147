export class Validator {
  static RX_NUMBER = /^[0-9]*\.?[0-9]+$/;

  static isNullOrUndefined(value: any) {
    return value === null || typeof value === 'undefined';
  }

  static isNumeric(value: any) {
    return Validator.RX_NUMBER.test(String(value));
  }

  static isDate(value: any) {
    return value instanceof Date;
  }

  static isNumber(value: any) {
    return typeof value === 'number';
  }

  static isObject(value: any) {
    return value !== null && typeof value === 'object';
  }

  static isPlainObject(value: any) {
    return Object.prototype.toString.call(value) === '[object Object]';
  }

  static isArray(value: any) {
    return Array.isArray(value);
  }

  static normalizeValue(value: any) {
    if (Validator.isNullOrUndefined(value)) {
      return '';
    }
    if (Validator.isNumeric(value)) {
      return Validator.toFloat(value);
    }
    return value;
  }

  static toFloat(value: any, defaultValue = NaN) {
    const float = parseFloat(value);
    return isNaN(float) ? defaultValue : float;
  }

  static toString(value: any, spaces = 2) {
    if (Validator.isNullOrUndefined(value)) {
      return '';
    }
    if (Validator.isArray(value) || (Validator.isPlainObject(value) && value.toString === Object.prototype.toString)) {
      return JSON.stringify(value, null, spaces);
    }
    return String(value);
  }

  static stringifyObjectValues(value: any): string {
    if (Validator.isNullOrUndefined(value)) {
      return '';
    }

    if (Validator.isObject(value) && !Validator.isDate(value)) {
      return Object.keys(value)
        .sort()
        .map(k => Validator.stringifyObjectValues(value[k]))
        .filter(v => !!v)
        .join(' ');
    }
    return Validator.toString(value);
  }
}
