
import { Component, Vue } from "vue-property-decorator";
import DiscardModal from "@/components/core/DiscardModal.vue";
import DeleteModal from "@/components/core/DeleteModal.vue";
import { notificationService } from "@/services/notification/notification.service";
import { ICONS_LOGO_AUTOBAHN } from "@/assets";
import { BASE_ROUTES } from "@/router";
import { keycloakService } from "./keycloak/keycloak.service";
import AOSHeader from "./components/aos/AOSHeader.component.vue";
import AOSNavBar from "./components/aos/AOSNavBar.component.vue";
import AOSSideBar from "./components/aos/sidebar/AOSSideBar.component.vue";
import { ISideBarLink } from "./components/aos/sidebar/sidebar-link.interface";
import { configService } from "./config/config.service";

@Component({
  components: {
    DiscardModal,
    DeleteModal,
    AOSHeader,
    AOSNavBar,
    AOSSideBar,
  },
})
export default class App extends Vue {
  notificationService = notificationService;
  keycloakService = keycloakService;
  configService = configService;
  ICONS_LOGO_AUTOBAHN = ICONS_LOGO_AUTOBAHN;
  BASE_ROUTES = BASE_ROUTES;

  links: ISideBarLink[] = [
    {
      route: BASE_ROUTES.VIEWER,
      name: "Verkehrslage Deutschland",
      icon: "fa-map",
    },
    {
      route: BASE_ROUTES.LISTENANSICHT,
      name: "Meldungsübersicht",
      icon: "fa-list",
    },
    {
      route: BASE_ROUTES.WFMS,
      name: "Workflow Management",
      icon: "fa-solar-panel",
    },
  ];

  metalinks: ISideBarLink[] = [
    {
      route: BASE_ROUTES.HILFE,
      name: "Dokumentation",
      icon: "fa-book",
    },
    // {
    //   route: BASE_ROUTES.RELEASENOTES,
    //   name: "Versionshinweise",
    //   icon: "fa-code-branch",
    // },
    {
      route: BASE_ROUTES.IMPRESSUM,
      name: "Impressum",
      icon: "fa-stamp",
    },
  ];

  get notifications() {
    return notificationService.notifications;
  }

  setFooterInfo() {
    const footerinfo = document.getElementById("footer-info");
    if (footerinfo) {
      let footerstring = "";
      footerstring = "SKM" + " | " + configService.getSystemumgebung() + " | " + configService.getGitTag();
      footerinfo.innerHTML = footerstring;
    }
  }

  mounted() {
    if (configService.getUseTestdatenReiszeit() === "true") {
      this.links.push({
        route: BASE_ROUTES.TESTDATEN,
        name: "Testdaten",
        icon: "fa-flask",
      });
    } 

    this.setFooterInfo();
  }
}
