import { TestdatenInterface } from './testdaten.interface';

export class Testdaten {
  // id: number;
  from_to: string;
  active: boolean;
  description: string;
  scenario: number;
  speed: number;
  loss_time: number;

  constructor(testdatenInterface: TestdatenInterface) {
    // this.id = testdatenInterface.id;
    this.from_to = testdatenInterface.from_to;
    this.active = testdatenInterface.active;
    this.description = testdatenInterface.description;
    this.scenario = testdatenInterface.scenario;
    this.speed = testdatenInterface.speed;
    this.loss_time = testdatenInterface.loss_time;
  }
}
