
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FormArray, formBuilder, FormBuilderValidators } from '@/helper/FormBuilder/form';
import { UploadUtils } from '@/helper/upload-utils';
import { Datei } from '@/@types/datei.interface';

@Component
export default class FileInput extends Vue {
  @Prop() form!: FormArray;
  @Prop({ type: String, default: '' }) label!: string;
  @Prop({ type: String, default: null }) placeholder!: string | null;
  @Prop({ type: Boolean }) required!: boolean;
  @Prop({ type: Boolean }) disabled!: boolean;
  @Prop({ type: Boolean }) readonly!: boolean;
  @Prop({ type: Boolean }) multiple!: boolean;

  value: File[] = [];

  getFormControl() {
    return this.form;
  }

  isLoading = false;

  async onInput(files: File | File[]) {
    this.isLoading = true;
    const dateien: Datei[] = [];
    if (Array.isArray(files)) {
      for (const file of files) {
        await this.loadFile(file);
      }
    } else {
      await this.loadFile((files as any)[0]);
    }
    this.isLoading = false;
    this.value = [];
    this.$emit('input');
  }

  async loadFile(file: File) {
    await UploadUtils.readFileIfExists(file, (datei) => {
      this.getFormControl().push(
        formBuilder.group({
          dataAsBase64Text: datei.dataAsBase64Text,
          name: [datei.name, FormBuilderValidators.maxLength(50)],
          beschreibung: ['', FormBuilderValidators.maxLength(50)],
        }),
      );
    });
  }
}
