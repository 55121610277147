
import { Component, Prop, Vue } from "vue-property-decorator";
import AOSSideBarLink from "./AOSSideBarLink.component.vue";
import { ISideBarLink } from "./sidebar-link.interface";

@Component({
  components: {
    AOSSideBarLink,
  },
})
export default class AOSSideBar extends Vue {
  @Prop() links!: ISideBarLink[];
  @Prop() metalinks!: ISideBarLink[];

  isCollapsed = true;
}
