
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Route } from '@/services/route/route.class';

@Component({})
export default class AktuelleReisezeitComponent extends Vue {
  @Prop() route!: Route;

  aktuelleReisezeit() {
    return (this.route.aktuelleReisezeit / 60).toFixed(0);
  }
}
