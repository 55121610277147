
import { Component, Vue } from "vue-property-decorator";
import { korridorService } from "@/services/korridor/korridor.service";
import { Route } from "@/services/route/route.class";
import { karteService } from "@/services/karte/karte.service";
import { RouteLayer } from "@/services/karte/route-layer";
import { apiService } from "@/services/api/api.service";
import { ICONS_SLIDE_IN, ICONS_SLIDE_OUT } from "@/assets";
import RichtungViewerComponent from "@/components/richtung-viewer/RichtungViewer.component.vue";
import { Richtung } from "@/services/richtung/richtung.class";
import { Korridor } from "@/services/korridor/korridor.class";

@Component({
  components: {
    RichtungViewerComponent,
  },
})
export default class KorridoreViewerView extends Vue {
  showMenu = false;

  isLoadingKorridore = false;
  korridorService = korridorService;
  apiService = apiService;

  ICONS_SLIDE_IN = ICONS_SLIDE_IN;
  ICONS_SLIDE_OUT = ICONS_SLIDE_OUT;

  collapseState: { [key: string]: boolean } = {};

  showCollapse(key: string) {
    this.collapseState[key] = true;
  }

  hideCollapse(key: string) {
    this.collapseState[key] = false;
  }

  isCollapseVisible(key: string, doIfVisible?: any): boolean {
    return !!this.collapseState[key];
  }

  // Update Korridore via setInterval
  timer = 0;

  created() {
    this.loadKorridore();
    this.timer = setInterval(this.updateKorridore, 30000);
  }

  updateKorridore() {
    // console.log('Aktualisiere Korridore und Routen')
    this.loadKorridore();
  }

  cancelAutoUpdate() {
    clearInterval(this.timer);
  }

  beforeUnmount() {
    this.cancelAutoUpdate();
  }

  async loadKorridore() {
    this.isLoadingKorridore = true;
    await korridorService.fetchAll();
    this.isLoadingKorridore = false;
  }

  onKarteReady() {
    // console.log("onKarteReady");
    this.routeLayer?.add();
  }

  get routeLayer() {
    return karteService.getLayer<RouteLayer>(RouteLayer);
  }

  onRouteShown(route: Route) {
    if (this.routeLayer?.visible) {
      this.routeLayer.zoomAufRoute(route);
    }
  }

  onCheckboxToggle() {
    // console.log('toggle')
  }

  onRichtungHighlight(korridor: Korridor, richtung: Richtung) {
    if (!this.routeLayer) {
      return;
    }
    if (this.routeLayer.isKorridorVisible(korridor)) {
      return;
    }
    // nicht den Korridor an/abschalten, wenn Richtung getoggelt wird
    // this.routeLayer.toggleKorridorVisibility(korridor);
  }
}
