import { apiService } from '../api/api.service';
import { notificationService } from '../notification/notification.service';
import { Richtung } from '../richtung/richtung.class';
import { richtungService } from '../richtung/richtung.service';
import { Korridor } from './korridor.class';
import { KorridorInterface } from './korridor.interface';
import { SaveKorridorCommand } from './save-korridor.command';

class KorridorService {
  private korridore: Korridor[] = [];

  private schnittstelle = 'korridor';

  async fetchAll() {
    try {
      this.korridore = ((await apiService.get(
        this.schnittstelle,
      )) as KorridorInterface[]).map((r) => new Korridor(r));
      richtungService.setRichtungen(
        this.korridore.reduce((richtungen, korridor) => {
          richtungen.push(...korridor.richtungen);
          return richtungen;
        }, [] as Richtung[]),
      );
    } catch (e) {
      console.error(e);
      notificationService.sendErrorNotification(
        'Fehler beim Abfragen der Korridore',
      );
    }
  }

  async fetchOne(id: number) {
    try {
      return new Korridor(
        (await apiService.get(
          this.schnittstelle + '/' + id,
        )) as KorridorInterface,
      );
    } catch (e) {
      notificationService.sendErrorNotification(
        `Fehler beim Abfragen des Korridors (${id})`,
      );
    }
  }

  async create(saveCommand: SaveKorridorCommand): Promise<Korridor> {
    const created = new Korridor(
      await apiService.post(this.schnittstelle, saveCommand),
    );
    this.korridore.push(created);
    return created;
  }

  async update(
    id: number,
    saveCommand: SaveKorridorCommand,
  ): Promise<Korridor> {
    const r = this.findById(id);
    if (!r) {
      throw new Error('Korridor nicht gefunden (Id)');
    }
    const index = this.findIndex(r);
    if (index === -1) {
      throw new Error('Korridor nicht gefunden (Index)');
    }
    const updated = new Korridor(
      await apiService.put(this.schnittstelle, id, saveCommand),
    );
    this.korridore.splice(index, 1, updated);
    return updated;
  }

  async delete(id: number) {
    const route = this.findById(id);
    if (!route) {
      throw new Error('Route nicht gefunden (Id)');
    }
    const index = this.findIndex(route);
    if (index === -1) {
      throw new Error('Route nicht gefunden(Index)');
    }
    await apiService.delete(this.schnittstelle, id);
    this.korridore.splice(index, 1);
  }

  getKorridore() {
    return this.korridore;
  }

  findIndex(korridor: Korridor) {
    return this.korridore.findIndex((r) => korridor === r);
  }

  findById(id: number) {
    return this.korridore.find((r) => r.id === id);
  }
}

export const korridorService = new KorridorService();
