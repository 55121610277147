import { karteService } from './karte.service';
import { karteParameterService } from '../karte-parameter/karte-parameter.service';

export abstract class Layer {
  name: string;

  abstract legendeImageUrl: string;
  abstract legendeImageUrlOn: string;

  abstract legende: { imgUrl: string; desc: string }[];

  public visible = false;
  public layerAnchorName = '';
  public displayInMenu: boolean;

  constructor(name: string, beforeLayer?: string, displayInMenu = true) {
    this.name = name;
    this.displayInMenu = displayInMenu;
    this.createLayerAnchor(beforeLayer);
  }

  createLayerAnchor(beforeLayer?: string) {
    this.layerAnchorName = karteService.createLayerAnchor(
      this.name,
      beforeLayer,
    );
  }

  abstract add(): Promise<void>;
  abstract remove(): Promise<void>;
  abstract update(): Promise<void>;

  abstract addEvents(): Promise<void>;
  abstract removeEvents(): Promise<void>;

  get map() {
    return karteService.getMap();
  }

  public toggle() {
    if (this.visible) {
      karteParameterService.removeLayer(this.name);
      this.remove();
    } else {
      karteParameterService.addLayer(this.name);
      this.add();
    }
  }

  public async reload() {
    await this.remove();
    await this.add();
    karteParameterService.addLayer(this.name);
  }
}
