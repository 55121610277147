
import { Component, Prop, Vue } from "vue-property-decorator";
import AOSSelect from "../AOSSelect.component.vue";
import { ITableFieldSorted } from "./table-field-sorted.interface";
import { ITableField } from "./table-field.interface";
import { ITableFilter } from "./table-filter.interface";

@Component({
  components: {
    AOSSelect,
  },
})
export default class AOSTableHeadCell extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  field!: ITableField;
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return;
    },
  })
  filters!: { [key: string]: ITableFilter };
  @Prop({
    required: true,
    type: Object,
  })
  sortedField!: ITableFieldSorted;

  get filter() {
    return this.filters ? this.filters[this.field.key] : null;
  }

  hover = false;
}
